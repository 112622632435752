import React, { useEffect, useMemo, useState } from "react";
import {
	ActivityLog,
	EditableSidePanel,
	SidePanel,
	TabList,
	Typography,
	Notification,
	ButtonV2,
} from "@components";
import { TabListTabText } from "@common/components/TabList/TabList";

import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import { truncArgs } from "@helpers/Formatters";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Rack } from "@common/types";
import {
	useRackQuery,
	useRackPatchMutation,
	useRackStorageSummaryQuery,
} from "@redux/inventory/Rack";
import InputV3 from "@common/components/InputV3/InputV3";

type RackSettingsProps = {
	handleShowEditRackModal: (show: boolean, rack: Rack) => void;
	handleRearrengeBoxModal: (show: boolean, rack: Rack) => void;
};

const MAX_RACK_NAME = 200;

export default function RackSettings(props: RackSettingsProps): JSX.Element {
	const {
		genericallyNamed: {
			isVisible,
			closePanel: _closePanel,
			data: { id },
		},
	} = useCommonPanelState("rackSettings");
	const { data: rack } = useRackQuery(id || skipToken);
	const isCategory = !!rack?.is_category;
	const rows = rack?.rows;
	const cols = rack?.columns;
	const viewOnly = !!rack?.is_archived;

	const [renameRack, _setRename] = useState<boolean>(false);
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const [updateRack] = useRackPatchMutation();

	const handleCancel = () => {
		setRename(false);
	};

	const [name, setName] = useState<string>("");
	const [tempName, setTempName] = useState<string>("");

	const [rackData, storageData] = useRackStorageSummary(rack?.id, isCategory);
	useEffect(() => {
		if (isVisible && rack) {
			setName(rack.name || "");
		}
	}, [rack, isVisible]);

	const setRename = (value: boolean) => {
		_setRename(value);
		if (value) setTempName(rack?.name || "");
	};
	const closePanel = () => {
		if (renameRack) {
			setRename(false);
		}
		_closePanel();
	};

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const handleSubmit = () => {
		const failure = (err?: string) => {
			console.log("Failed to edit the rack", err, rack);
			Notification.warning({
				message: "Failed to edit the rack",
			});
		};
		if (!validateForm() || !rack) return;
		updateRack({
			id: rack.id,
			name: tempName.trim(),
		})
			.unwrap()
			.then((rack) => {
				setRename(false);
				setName(rack.name);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${rack.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(failure);
	};

	const detailContent = (
		<div className="rack-settings">
			{!renameRack ? (
				<EditableSidePanel
					title={name}
					type={isCategory ? "category" : "rack"}
					edit={renameRack}
					showEdit={() => setRename(true)}
					viewOnly={viewOnly}
					handleShowEditRackModal={() =>
						rack && props.handleShowEditRackModal(true, rack)
					}
					dataCy="rack-settings-editable-panel"
				>
					{!isCategory && (
						<>
							<div className="sidePanel-infoView-layout">
								<Typography className="sidePanel-infoView-leftSide">
									Dimension
								</Typography>
								<Typography data-cy="rack-settings-displayed-dimension">
									{rows} x {cols}
								</Typography>
							</div>
							{!viewOnly && (
								<div className="sidePanel-infoView-layout">
									<Typography className="sidePanel-infoView-leftSide">
										More options
									</Typography>
									<ButtonV2
										className="modal-button"
										onClick={() =>
											rack
												? props.handleRearrengeBoxModal(
														true,
														rack
												  )
												: null
										}
										type="link"
										style={{
											marginTop: -4,
											marginLeft: -16,
										}}
									>
										Rearrange
									</ButtonV2>
								</div>
							)}
						</>
					)}
				</EditableSidePanel>
			) : (
				<EditableSidePanel
					edit={renameRack}
					type={isCategory ? "category" : "rack"}
					inlineEdit
					showEdit={handleCancel}
					onConfirm={handleSubmit}
					validateForm={validateForm}
					dataCy="rack-settings-editable-panel"
				>
					<div className="edit-form">
						<InputV3
							value={tempName}
							onChange={(e) => setTempName(e.target.value)}
							required
							maxLength={MAX_RACK_NAME}
							dataCy="rack-category-edit-name"
						/>
					</div>
				</EditableSidePanel>
			)}
		</div>
	);

	const activityContent = (
		<ActivityLog
			activityFilter={{
				location_model: "Rack",
				location_object_id: rack?.id,
			}}
		/>
	);

	const analyticName = isCategory ? "Category" : "Rack";

	const tabItems = [
		{
			key: 1,
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: detailContent,
		},
		{
			key: 2,
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: activityContent,
		},
		{
			key: 3,
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics}
				/>
			),
			tabcontent: (
				<AnalyticsTabContent
					title={`Distribution in ${rack?.name}`}
					subtitle={"Available space in rack"}
					storageSummary={{
						dataSource: storageData,
						columns: [
							{
								title: "Box",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Items",
								dataIndex: "items",
								key: "items",
								align: "right",
								width: 88,
							},
						],
						mockDataSource: storageData,
					}}
					spaceLeft={{
						dataSource: rackData,
						columns: [
							{
								title: "Box",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Additional items allowed",
								dataIndex: "available_items",
								key: "available_items",
								align: "right",
							},
						],
						mockDataSource: rackData,
					}}
				/>
			),
		},
	];

	return (
		<SidePanel
			isVisible={isVisible}
			onClose={() => closePanel()}
			header={isCategory ? "Category settings" : "Rack settings"}
			dataCy="rack-settings"
		>
			<TabList inSettings tabListItems={tabItems} largeSize={true} />
		</SidePanel>
	);
}

/** convert rackStorageSummaryData to rackData and storageData */
export const useRackStorageSummary = (
	rackId: number | undefined,
	isCategory: boolean
) => {
	const { data: rackStorageSummary } = useRackStorageSummaryQuery(
		rackId || -1,
		{ skip: !rackId || isCategory }
	);

	const [rackData, storageData] = useMemo(() => {
		const data = rackStorageSummary;
		const rackData = data?.available_space.map((a) => ({
			name: a.name,
			available_items: a.available_items,
		}));
		const storageData = data?.storage_summary.map((s) => ({
			name: s.name,
			items: s.items,
		}));
		return [rackData, storageData] as const;
	}, [rackStorageSummary, isCategory]);
	return [rackData, storageData] as const;
};
