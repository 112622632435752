import React, { forwardRef, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData } from "react-draggable";
import { BaseElement, Editor, Path, Point, Range, Transforms } from "slate";
import { useFocused, useSelected } from "slate-react";
import {
	API,
	axios,
	GenemodIcon,
	Notification,
	UploadAttachmentModal,
} from "@common/components";
import { Block } from "../../nodes";
import "./ImageNode.scss";
import cn from "classnames";
import { nanoid } from "nanoid";
import { useDocumentImageQuery } from "@redux/ProjectManagement/PmApiSlice";
import { useAppDispatch } from "@redux/store";
import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";
import { DocumentImage, GenemodDocumentUUID } from "@common/types";
import { EditorCommands } from "@components/Editor/helpers/editorCommons";

export const DataGenemodImage = "data-genemod-image";

const MAX_IMAGE_WIDTH = 712;
const MIN_IMAGE_WIDTH = 48;
const MIN_IMAGE_HEIGHT = 48;

export type ImageNodeElement = BaseElement & {
	type: "image";
	url: string;
	id: number;
	uploadUUID: string;
	width: number;
	captionHasFocus?: boolean;
	hasCaption?: boolean;
};

export const ImageNode = {
	...Block,
	type: "image",
	id: -1,
	uploadUUID: "",
	width: MAX_IMAGE_WIDTH,

	render(props: any, node: any) {
		return (
			<ResizeableImage {...props.attributes} node={node}>
				{props.children}
			</ResizeableImage>
		);
	},

	getNewImageNode() {
		return {
			id: nanoid(),
			type: this.type,
			width: this.width,
		};
	},
	insertNewImage(editor: Editor, uploadUUID: string) {
		Transforms.insertNodes(editor, {
			...this.getNewImageNode(),
			uploadUUID: uploadUUID,
			children: [{ text: "" }],
		});
		this.focusEditor(editor);
	},
	insertExistingImage(
		editor: Editor,
		uploadUUID: string,
		width: number,
		hasCaption: boolean,
		text: string,
		path?: Path
	) {
		if (path) {
			Transforms.insertNodes(
				editor,
				{
					...this.getNewImageNode(),
					uploadUUID: uploadUUID,
					width,
					hasCaption,
					children: [{ text }],
				},
				{
					at: path,
				}
			);
		} else {
			Transforms.insertNodes(editor, {
				...this.getNewImageNode(),
				uploadUUID: uploadUUID,
				width,
				hasCaption,
				children: [{ text }],
			});
		}
	},
	setSize(editor: Editor, id: number, width: number) {
		Transforms.setNodes(editor, { width: width } as any, {
			at: [],
			match: (n) =>
				(n as any).type === ImageNode.type && (n as any).id === id,
		});
	},
	setHasCaption(editor: Editor, id: number, hasCaption: boolean) {
		Transforms.setNodes(editor, { hasCaption } as any, {
			at: [],
			match: (n) =>
				(n as any).type === ImageNode.type && (n as any).id === id,
		});
	},
	setCaptionHasFocus(editor: Editor, id: number, captionHasFocus: boolean) {
		Transforms.setNodes(editor, { captionHasFocus } as any, {
			at: [],
			match: (n) =>
				(n as any).type === ImageNode.type && (n as any).id === id,
		});
	},
	plugin(editor: Editor) {
		const editorCommands = new EditorCommands();
		const {
			normalizeNode,
			onKeyDown,
			deleteFragment,
			insertFragment,
			deleteBackward,
			isVoid,
		} = editor;
		editor.normalizeNode = (entry: any) => {
			editorCommands.normalizeNode(
				entry,
				this.type,
				editor,
				normalizeNode
			);
		};

		editor.insertFragment = (fragment: any) =>
			editorCommands.insertFragment(
				fragment,
				this.type,
				editor,
				insertFragment
			);

		editor.deleteFragment = () =>
			editorCommands.deleteFragment(this.type, editor, deleteFragment);

		editor.deleteBackward = (unit) => {
			const selection = editor.selection;
			if (selection && Range.isCollapsed(selection)) {
				const [imageNode] = Editor.nodes(editor, {
					at: selection,
					match: (n) =>
						(n as any).type === "image" &&
						(n as any).captionHasFocus,
				});
				if (imageNode) {
					const [, path] = imageNode;
					const start = Editor.start(editor, path);
					if (Point.equals(selection.anchor, start)) {
						return;
					}
				}
			}
			deleteBackward(unit);
		};

		editor.onKeyDown = (evt) => {
			const selection = editor.selection;
			if (selection && evt.key === "c" && (evt.metaKey || evt.ctrlKey)) {
				const nodesSelected = Array.from(
					Editor.nodes(editor, { at: selection })
				);
				if (nodesSelected.length === 2) {
					const [imageNode] = Editor.nodes(editor, {
						at: selection,
						match: (n) => (n as any).type === "image",
					});
					if (imageNode) {
						const [node] = imageNode;
						navigator.clipboard.writeText(JSON.stringify(node));
						evt.preventDefault();
						return;
					}
				}
			}
			if (selection && evt.key === "Enter") {
				const [imageNode] = Editor.nodes(editor, {
					at: selection,
					match: (n) => (n as any).type === "image",
				});
				if (imageNode) {
					evt.preventDefault();
					const after = [selection.focus.path[0] + 1];
					Transforms.select(editor, after);
					return;
				}
			}
			onKeyDown(evt);
		};

		editor.isVoid = (element) => {
			if (element.type === "image") {
				return !element.hasCaption || !element.captionHasFocus;
			}
			return editorCommands.isVoid(element, this.type, isVoid);
		};

		return editor;
	},
};

/** resizeable image component for Experiment */
const ResizeableImage = forwardRef((props: any, ref: any) => {
	const editor = useGenemodSlate();
	const { node } = props;
	const [width, setWidth] = useState(0);
	const focused = useFocused() && useSelected();
	const [showIndicator, setShowIndicator] = useState(false);
	const captionRef = useRef<any>(null);
	const caption = node.children[0].text;
	// We fetch images rather than using document.images so that images
	// added by other users during live editing will always be fetched
	const { data: imageData } = useDocumentImageQuery(node.uploadUUID);
	const imgWidth = width ? width : node.width;

	const handleClickOutside = (event: MouseEvent) => {
		if (captionRef.current && !captionRef.current.contains(event.target)) {
			ImageNode.setCaptionHasFocus(editor, node.id, false);
			if (!caption?.trim().length) {
				ImageNode.setHasCaption(editor, node.id, false);
			}
			return;
		}
		ImageNode.setCaptionHasFocus(editor, node.id, true);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [caption]);

	const imagePermission =
		editor.experimentIsEditable && editor.permissions.image;
	// return whether this node is selected or not
	const isSelected = () => {
		const [...match] = Editor.nodes(editor, {
			match: (n) =>
				(n as any).type === node.type && (n as any).id === node.id,
		});
		return !!match.length;
	};

	// get current node (slate) position in editro
	const getNodePosition = () => {
		const [imageNodes] = Editor.nodes(editor, {
			at: [],
			match: (n: any) => n.type === ImageNode.type && n.id === node.id,
		});
		return imageNodes[1];
	};

	const renderDraggableIcon = (position: "left" | "right") =>
		imagePermission && (
			<div className="draggable-icon-container">
				<Draggable
					axis="none"
					onDrag={(_e, data: DraggableData) => {
						if (data.deltaX !== 0) {
							const dir = position === "right" ? 1 : -1;
							// onlyif there are movement on x-axis
							const w = width + dir * data.deltaX;
							const h = ref.current?.height + dir * data.deltaX;
							if (
								w > MAX_IMAGE_WIDTH ||
								w < MIN_IMAGE_WIDTH ||
								h < MIN_IMAGE_HEIGHT
							) {
								// out of resize boundary
								return;
							}
							setWidth(w);
						}
					}}
					onStart={() => {
						setWidth(node.width);
					}}
					onStop={() => {
						setWidth(0);
						// only update the node when dragging is finished
						ImageNode.setSize(editor, node.id, width);
					}}
				>
					<GenemodIcon
						className="draggable-icon"
						size="large"
						name="drag-handle-resize"
					/>
				</Draggable>
			</div>
		);

	const focusOnCaption = () => {
		ImageNode.setCaptionHasFocus(editor, node.id, true);
		captionRef.current?.focus();
		if (editor.selection) {
			const path = Editor.path(editor, editor.selection);
			Transforms.select(editor, Editor.end(editor, path));
		}
	};

	const addCaption = () => {
		ImageNode.setHasCaption(editor, node.id, true);
		ImageNode.setCaptionHasFocus(editor, node.id, true);
		captionRef.current?.focus();
	};

	return (
		<div
			{...props}
			ref={ref}
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{ width: imgWidth }}
				className={cn("outterContainer", {
					["outterContainer_focused"]: isSelected() || focused,
				})}
			>
				<div
					className={cn("experiment-image-container", {
						["experiment-image-container_focused"]:
							isSelected() || focused,
					})}
					style={{
						userSelect: "none",
					}}
					contentEditable={false}
				>
					{renderDraggableIcon("left")}
					{isSelected() && (
						<div
							className="experiment-image-cover"
							style={{
								width: imgWidth + 16,
								height: "calc(100% + 16px)",
								pointerEvents: "none",
							}}
						/>
					)}
					<img
						className="experiment-image"
						data-genemod-image={DataGenemodImage}
						src={imageData?.upload}
						alt="genemod-experiment-image"
						width="100%"
						onDragEnter={(e) => {
							const types = e.dataTransfer.types;
							if (types.length > 0 && types[0] === "Files") {
								// only show indicator, if image file is dragged
								setShowIndicator(true);
							}
						}}
						onDragLeave={() => {
							setShowIndicator(false);
						}}
						onDropCapture={(e) => {
							// prevent to run editor.insertData
							e.stopPropagation();
							const pos = getNodePosition();
							const after = Editor.after(editor, pos);
							if (after) {
								Transforms.select(editor, after);
							}
							setShowIndicator(false);
						}}
						onClick={() => {
							const pos = getNodePosition();
							Transforms.select(editor, pos);
						}}
					/>
					{renderDraggableIcon("right")}
				</div>
				<div className="captionContainer">
					{!node.hasCaption ? (
						<div
							style={{ width: imgWidth }}
							className="addCaptionText"
							onClick={addCaption}
						>
							Add Caption
						</div>
					) : null}
					{node.hasCaption && !node.captionHasFocus ? (
						<div onClick={focusOnCaption} className="captionText">
							{caption}
						</div>
					) : null}
					<div ref={captionRef} className="captionText">
						{props.children}
					</div>
				</div>
			</div>
			{showIndicator && <div className="experiment-indicator-bar" />}
		</div>
	);
});

/** insert Image Modal  */
export function InsertImageModal(props: {
	visible: boolean;
	documentId: GenemodDocumentUUID;
	onCancel: () => void;
	onUploadCompleted: (uuid: string) => void;
}): JSX.Element {
	const dispatch = useAppDispatch();
	const [uploadingRate, setUploadingRate] = useState(0.0);

	// called after image is attached to the modal
	const handleUploadCompleted = async (imageData: any) => {
		// const image = await postAttachmentToDB(imageData);
		const config = {
			onUploadProgress: function (progressEvent: any) {
				const percentCompleted = Math.floor(
					(progressEvent.loaded / progressEvent.total) * 100
				);
				setUploadingRate(percentCompleted);
			},
		};
		const data = new FormData();
		data.append("upload", imageData);
		const route = API.pm
			.documents()
			.get(props.documentId)
			.imageUpload()
			.getRoute();
		axios.post<DocumentImage>(route, data, config).then((response) => {
			if ("error" in response) {
				Notification.warning({
					message:
						"Failed to upload the image. Try again or contact us if it continues.",
				});
			} else {
				props.onUploadCompleted(response.data.uuid);
			}
		});
	};

	return (
		<UploadAttachmentModal
			visible={props.visible}
			type="image"
			onCancel={props.onCancel}
			uploadCompleted={handleUploadCompleted}
			uploadingRate={uploadingRate}
		/>
	);
}
