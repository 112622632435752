import React, { useEffect } from "react";
import { useDropzone, Accept } from "react-dropzone";
import {
	Modal,
	Typography,
	GenemodIcon,
	ProgressBar,
	CommonDeleteModal,
	DemoWrapper,
	DemoSection,
	Demo,
	UpgradeButton,
} from "@components";
import { IMAGE_ACCEPTED_FILES } from "@common/types/FileAttachment";
import { ColorCssVarMap } from "@common/styles/Colors";
import styles from "./UploadAttachmentModalV2.module.scss";
import GoogleDriveIcon from "../GoogleDocsIntegration/GoogleDriveIcon";
import { ENABLE_GOOGLE_DRIVE } from "../GoogleDocsIntegration/UseGooglePickerHook";

type UploadAttachmentModalV2Props = {
	visible: boolean;
	onCancel: () => void;
	uploadCompleted: (file: any) => void;
	uploadingRate: number;
	type?: "attachment" | "image";
	openGoogleDrive: () => void;
	isGoogleDriveRestricted: boolean;
};

function UploadAttachmentModalV2({
	visible,
	onCancel,
	uploadCompleted,
	uploadingRate,
	type = "attachment",
	openGoogleDrive,
	isGoogleDriveRestricted,
}: UploadAttachmentModalV2Props): JSX.Element {
	const generateAcceptedFiles = (fileTypes: any) => {
		return fileTypes.map((ext: any) => "." + ext);
	};

	const VALID_IMG_EXTENTIONS = generateAcceptedFiles(IMAGE_ACCEPTED_FILES);

	const uploadType = type;
	const acceptedFileList =
		uploadType === "attachment" ? [] : VALID_IMG_EXTENTIONS;

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={496}
			footer={null}
			title={`Upload ${type === "attachment" ? "file" : "image"}`}
			subtitle={
				type !== "attachment"
					? `Supported type: ${acceptedFileList.join(", ")}`
					: undefined
			}
		>
			<UploadAttachmentModalV2Content
				visible={visible}
				uploadCompleted={uploadCompleted}
				uploadingRate={uploadingRate}
				acceptedFileList={acceptedFileList}
				openGoogleDrive={openGoogleDrive}
				isGoogleDriveRestricted={isGoogleDriveRestricted}
			/>
		</Modal>
	);
}
type UploadAttachmentModalV2ContentProps = {
	visible: boolean;
	uploadCompleted: (file: any) => void;
	uploadingRate: number;
	acceptedFileList?: Accept;
	openGoogleDrive: () => void;
	isGoogleDriveRestricted: boolean;
};
export const UploadAttachmentModalV2Content = ({
	visible,
	uploadCompleted,
	uploadingRate,
	acceptedFileList,
	openGoogleDrive,
	isGoogleDriveRestricted,
}: UploadAttachmentModalV2ContentProps) => {
	const errorMessage = {
		INVALID: "File type not supported.",
		OVERSIZE: "File size exceeds 50MB.",
	};

	const MAX_SIZE = 50 * (1024 * 1024); //50MB
	const [error, setError] = React.useState<any | null>(null);
	const [fileData, setFileData] = React.useState<any | null>(null);

	useEffect(() => {
		if (!visible) {
			resetPicker();
		}
	}, [visible]);

	const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
		//If there is not accpectedFiles or there is a fileRejection file and it is same as first uploaded file, then show error
		if (
			!acceptedFiles[0] ||
			(fileRejections[0] &&
				event.dataTransfer.files[0] === fileRejections[0].file)
		) {
			setError({
				fileName: fileRejections[0].file.name,
				message:
					fileRejections[0].errors[0].code === "file-invalid-type"
						? errorMessage.INVALID
						: errorMessage.OVERSIZE,
			});
		} else {
			setFileData(acceptedFiles[0]);
			uploadCompleted(acceptedFiles[0]);
		}
	};
	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
		accept: acceptedFileList,
		noClick: true,
		noKeyboard: true,
		multiple: true,
		maxSize: MAX_SIZE,
	});

	const resetPicker = () => {
		setError(null);
		setFileData(null);
	};
	return (
		<div className={styles.uploadFile}>
			<div
				className={styles.importdropzone}
				{...getRootProps({
					style: {
						outline: "none",
						borderColor: "",
						overflow: "hidden",
						flexDirection: "column",
						backgroundColor: isDragActive
							? ColorCssVarMap["layer-hover-01"]
							: "transparent",
					},
				})}
			>
				<input {...getInputProps()} />
				{error ? (
					<UploadAttachmentModalV2ErrorView
						error={error}
						resetPicker={resetPicker}
					/>
				) : fileData ? (
					<UploadAttachmentModalV2ProgressBarView
						uploadingRate={uploadingRate}
						fileData={fileData}
					/>
				) : (
					<UploadAttachmentModalV2RegularView
						open={open}
						openGoogleDrive={openGoogleDrive}
						isGoogleDriveRestricted={isGoogleDriveRestricted}
					/>
				)}
			</div>
		</div>
	);
};

type UploadAttachmentModalV2ErrorViewProps = {
	error: {
		fileName: string;
		message: string;
	};
	resetPicker: () => void;
};

const UploadAttachmentModalV2ErrorView = ({
	error,
	resetPicker,
}: UploadAttachmentModalV2ErrorViewProps) => (
	<div className={styles.error}>
		<div className={styles.errorFile}>
			<GenemodIcon name="file" decorative style={{ marginRight: 8 }} />
			<Typography>{error.fileName}</Typography>
			<GenemodIcon
				name="cancel"
				size="large"
				style={{ marginLeft: 10 }}
				color="red"
				onClick={resetPicker}
			/>
		</div>
		<div className="errorMessage">
			<Typography>Could not upload. {error.message}</Typography>
		</div>
	</div>
);

type UploadAttachmentModalV2RegularViewProps = {
	open: () => void;
	openGoogleDrive: () => void;
	isGoogleDriveRestricted: boolean;
};

const UploadAttachmentModalV2RegularView = ({
	open,
	openGoogleDrive,
	isGoogleDriveRestricted,
}: UploadAttachmentModalV2RegularViewProps) => (
	<div className={styles.contentContainer}>
		<div onClick={open} className={styles.buttonContainer}>
			<GenemodIcon name="upload" />
			<Typography
				color="text-secondary-v2"
				variant="headline5"
				medium
				resize={false}
				className={styles.uploadAction}
			>
				Click or drag file here to upload
			</Typography>
		</div>
		{ENABLE_GOOGLE_DRIVE && (
			<>
				<div className={styles.divider} />
				<div
					onClick={openGoogleDrive}
					className={styles.googleDriveButton}
				>
					<GoogleDriveIcon />
					<Typography
						color="text-tertiary-v2"
						variant="body"
						resize={false}
					>
						Attach files from Google Drive
					</Typography>
					{isGoogleDriveRestricted ? (
						<UpgradeButton onClick={() => {}} type="tag" />
					) : undefined}
				</div>
			</>
		)}
	</div>
);

type UploadAttachmentModalV2ProgressBarViewProps = {
	uploadingRate: number;
	fileData: File;
};

const UploadAttachmentModalV2ProgressBarView = ({
	uploadingRate,
	fileData,
}: UploadAttachmentModalV2ProgressBarViewProps) => (
	<div className={styles.progressbar}>
		<ProgressBar
			value={uploadingRate / 100}
			suffix={uploadingRate + "%"}
			label={fileData.name || ""}
		/>
	</div>
);

type DeleteFileModalProps = {
	visible: boolean;
	onCancel: () => void;
	onDelete: () => void;
};

export function DeleteAttachmentModal({
	visible,
	onCancel,
	onDelete,
}: DeleteFileModalProps): JSX.Element {
	return (
		<CommonDeleteModal
			visible={visible}
			titleObject="attachment"
			bodyObject="this attachment"
			onOk={onDelete}
			onCancel={onCancel}
		/>
	);
}

export default UploadAttachmentModalV2;

export function UPLOADATTACHMENTV2_DEMO() {
	const generateAcceptedFiles = (fileTypes: any) => {
		return fileTypes.map((ext: any) => "." + ext);
	};
	const VALID_IMG_EXTENTIONS = generateAcceptedFiles(IMAGE_ACCEPTED_FILES);

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Upload Attachment Modal Content"
					description="Upload Attachment Modal Content"
					class
				>
					<div style={{ width: "395px" }}>
						<UploadAttachmentModalV2Content
							visible={true}
							uploadCompleted={() => {
								console.log("uploadedCompleted");
							}}
							openGoogleDrive={() => {}}
							uploadingRate={10.0}
							acceptedFileList={VALID_IMG_EXTENTIONS}
							isGoogleDriveRestricted={false}
						/>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
