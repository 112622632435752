import GenemodIcon from "@common/components/GenemodIcon/GenemodIcon";
import Input from "@common/components/Input/Input";
import Label from "@common/components/Label";
import Popover from "@common/components/Popover/Popover";
import Typography from "@common/components/Typography/Typography";
import React, { useEffect, useState } from "react";
import TagSearch from "./TagPopover";
import styles from "./index.module.scss";
import LabelV2, { NewLabel } from "@common/components/LabelV2";

const wrapperProps = {
	style: {
		paddingTop: 0,
		paddingBottom: 0,
		margin: 0,
		height: 26,
	},
};

export type Tag = {
	id?: number;
	name: string;
};

type Props = {
	selectedTags?: Tag[];
	tagsList?: Tag[];
	onCreateTag?: (tag: string) => void;
	onSelectTag?: (tag: Tag) => void;
	onRemoveTag?: (tagId: number) => void;
	isArchived: boolean;
};

const Tags = ({
	selectedTags,
	tagsList,
	onRemoveTag,
	onCreateTag,
	onSelectTag,
	isArchived,
}: Props) => {
	const [newTag, setNewTag] = useState("");
	const [showInput, setShowInput] = useState(false);
	const filteredTags = tagsList
		?.filter((tag) => !selectedTags?.some((t) => t.name === tag.name))
		.filter((tag) => tag.name.toLowerCase() !== newTag.toLowerCase());

	useEffect(() => {
		setNewTag("");
	}, [tagsList, selectedTags]);

	return (
		<div className={styles.container}>
			{selectedTags?.map((tag) => (
				<LabelV2
					key={tag.id}
					onClose={() => (tag.id ? onRemoveTag?.(tag?.id) : null)}
					noElipsis
				>
					{tag.name}
				</LabelV2>
			))}
			{onCreateTag && onSelectTag ? (
				<>
					<NewLabel
						onLabelCreate={(val) => {
							onCreateTag(val);
						}}
						dataSource={filteredTags?.map((t) => t.name) || []}
						showInput={showInput}
						setShowInput={(val) => {
							setShowInput(val);
						}}
						value={newTag}
						onChange={(val) => {
							setNewTag(val);
						}}
						dotted
						archived={isArchived}
					/>
				</>
			) : null}
		</div>
	);
};

export default Tags;
