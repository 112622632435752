import { createApi } from "@reduxjs/toolkit/query/react";
import API from "@API";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import {
	CustomFields,
	ExperimentId,
	MetadataUUID,
	UpdateCustomFields,
} from "@common/types";
import {
	ExperimentMetadata,
	ItemMetadata,
} from "@common/types/ExperimentMetadata";
import { Tag } from "@common/components/Metadata/Tags";

const TAG_TYPES = [
	"ExperimentMetadata",
	"TagsMetadata",
	"CustomFieldsMetadata",
	"ItemMetadata",
] as const;

export const metadataApi = createApi({
	reducerPath: "metadataApi",
	baseQuery: genemodBaseQuery({ baseUrl: API.metadata.baseRoute }),
	tagTypes: TAG_TYPES,
	endpoints: (builder) => {
		// ~~~~~~~~~~~~~~~~ Experiment Metadata ~~~~~~~~~~~~~~~~
		const experimentMetadata = {
			experimentMetadata: builder.query<
				ExperimentMetadata,
				{ experimentId: ExperimentId }
			>({
				query: ({ experimentId }) => ({
					url: `v1/pm/experiment-file/${experimentId}/`,
				}),
				providesTags: ["ExperimentMetadata"],
			}),
			itemMetadata: builder.query<ItemMetadata, { itemId: number }>({
				query: ({ itemId }) => ({
					url: `v1/freezer/item/${itemId}/`,
				}),
				providesTags: ["ItemMetadata"],
			}),
		} as const;
		// ~~~~~~~~~~~~~~~~ /Experiment Metadata ~~~~~~~~~~~~~~~~

		// ~~~~~~~~~~~~~~~~ Tags Metadata ~~~~~~~~~~~~~~~~
		const tagsMetadata = {
			tagsMetadata: builder.query<Tag[], null>({
				query: () => ({
					url: `/v1/tags/`,
				}),
				providesTags: ["TagsMetadata"],
			}),
			tagsMetadataPost: builder.mutation<
				Tag[],
				{ metadataUuid: MetadataUUID; tags: Tag[] }
			>({
				query: ({ metadataUuid, tags }) => ({
					url: `/v1/${metadataUuid}/tags/`,
					method: "POST",
					body: tags,
				}),
				invalidatesTags: [
					"ExperimentMetadata",
					"ItemMetadata",
					"TagsMetadata",
				],
				async onQueryStarted({ tags }, { dispatch, queryFulfilled }) {
					const patchResult = dispatch(
						metadataApi.util.updateQueryData(
							"tagsMetadata",
							null,
							(draft) => {
								draft.push(...tags);
							}
						)
					);
					queryFulfilled.catch(patchResult.undo);
				},
			}),
			tagsMetadataDelete: builder.mutation<
				Tag[],
				{ metadataUuid: MetadataUUID; tagId: number }
			>({
				query: ({ metadataUuid, tagId }) => ({
					url: `/v1/${metadataUuid}/tags/${tagId}/`,
					method: "DELETE",
				}),
				// it is not necessary to invalidate TagsMetadata since we are not
				// deleting the tag from the list of tags, but from the list of associate tags
				// which is cached in ExperimentMetadata
				invalidatesTags: [
					"ExperimentMetadata",
					"ItemMetadata",
					"TagsMetadata",
				],
				async onQueryStarted({ tagId }, { dispatch }) {
					dispatch(
						metadataApi.util.updateQueryData(
							"tagsMetadata",
							null,
							(draft) => {
								const index = draft.findIndex(
									(tag) => tag.id === tagId
								);
								if (index !== -1) draft.splice(index, 1);
							}
						)
					);
				},
			}),
		};
		// ~~~~~~~~~~~~~~~~ /Tags Metadata ~~~~~~~~~~~~~~~~

		const customFieldsMetadata = {
			customFieldsMetadataPost: builder.mutation<
				CustomFields,
				{ metadataUuid: MetadataUUID; customFields: CustomFields }
			>({
				query: ({ metadataUuid, customFields }) => ({
					url: `/v1/${metadataUuid}/custom_fields/`,
					method: "POST",
					body: customFields,
				}),
				invalidatesTags: ["ExperimentMetadata", "ItemMetadata"],
			}),
			customFieldsMetadataPatch: builder.mutation<
				CustomFields,
				{ metadataUuid: MetadataUUID; customFields: UpdateCustomFields }
			>({
				query: ({ metadataUuid, customFields }) => ({
					url: `/v1/${metadataUuid}/custom_fields/`,
					method: "PATCH",
					body: [{ ...customFields }],
				}),
				invalidatesTags: ["ExperimentMetadata", "ItemMetadata"],
			}),
			customFieldsMetadataDelete: builder.mutation<
				CustomFields,
				{ metadataUuid: MetadataUUID; customFieldKey: string }
			>({
				query: ({ metadataUuid, customFieldKey }) => ({
					url: `/v1/${metadataUuid}/custom_field/${customFieldKey}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["ExperimentMetadata", "ItemMetadata"],
			}),
		};

		return {
			...experimentMetadata,
			...tagsMetadata,
			...customFieldsMetadata,
		};
	},
});

export const {
	// Experiment Metadata
	useExperimentMetadataQuery,
	useItemMetadataQuery,
	// Tags Metadata
	useTagsMetadataQuery,
	useTagsMetadataPostMutation,
	useTagsMetadataDeleteMutation,
	// Custom Fields Metadata
	useCustomFieldsMetadataPostMutation,
	useCustomFieldsMetadataPatchMutation,
	useCustomFieldsMetadataDeleteMutation,
} = metadataApi;
