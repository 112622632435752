import React, { useMemo, useState } from "react";
import column_styles from "@common/components/Table/ItemColumnWidth.module.scss";
import {
	Item,
	CustomItemTypeField,
	FieldType as SchemaFieldType,
} from "@common/types";
import { ColumnProps } from "antd/lib/table";
import {
	GenemodIcon,
	Typography,
	UserAvatar,
	ButtonV2,
	ResponsiveTable,
	CheckboxV2,
	LoadingSpinner,
} from "@common/components";
import { THEME_PREFERENCE } from "@common/components/Theme/context/ThemeTypes";
import { useTheme } from "@components/Theme/context/ThemeContext";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	COLUMN_FIELDS,
	FIELD_TYPES,
	PREDEFINED_SCHEMA,
} from "@containers/Freezer/Category/ItemListWidth";
import { useWindowDimensions } from "@helpers/Hooks";
import moment from "moment";
import styles from "./FreezerItemList.module.scss";
import cn from "classnames";
import { formatCustomId } from "@common/types";
import { useItemGroup } from "@containers/Freezer/table/BoxTableHooks";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useSearchSettingsQuery } from "@redux/freezer/FreezerApiSlice";
import { useItemTypeQuery } from "@redux/inventory/Item";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";
import { useParams } from "@helpers/URLParams";

type FreezerItemListProps = {
	onAddItem: () => void;
	onDelete: () => void;
	selectedItemIDs: number[];
	setSelectedItemIDs: (items: number[]) => void;
};

export default function FreezerItemList({
	onAddItem,
	onDelete,
	selectedItemIDs,
	setSelectedItemIDs,
}: FreezerItemListProps): JSX.Element {
	const {
		viewOnly,
		item_group,
		items,
		setItemIdInUrl: setItemId,
		itemIdInUrl: itemId,
	} = useItemGroup();

	const { data: searchSettings } = useSearchSettingsQuery();
	const { data: itemType, isLoading: isItemTypeLoading } = useItemTypeQuery(
		item_group?.item_type ?? skipToken
	);
	const [loading, setLoading] = useState(false);
	const { deleteParam } = useParams();
	const { handleArchiveOrRestoreButton } = useRepository();
	const { height } = useWindowDimensions();
	const [theme] = useTheme();
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("itemGroupSettings");

	const getFieldDataByFieldName = (fieldName: keyof typeof COLUMN_FIELDS) => {
		const column_field = COLUMN_FIELDS[fieldName];
		const field_data = {
			className: column_styles[column_field.className],
			title: (column_field as any).abbr || column_field.label,
			dataIndex: fieldName,
			key: fieldName,
			sorter: (a: any, b: any) =>
				(a[fieldName] || "").localeCompare(b[fieldName] || ""),
		} as ColumnProps<Item>;
		if (fieldName === "notes") {
			field_data["width"] = 300;
			field_data["ellipsis"] = true;
		}
		if (fieldName === "added_by") {
			field_data.render = (value, record) => (
				<UserAvatar user={record.created_by} displayName />
			);
		} else if (fieldName === "location") {
			field_data.render = (value, record) => (
				<Typography>{record.location_data.item_group_name}</Typography>
			);
		}
		if ("type" in column_field) {
			switch (column_field.type) {
				case FIELD_TYPES.DATE:
					field_data.render = (value, record) =>
						moment(record.created_at).format("MMM DD, YYYY");
					break;
				case FIELD_TYPES.NUMBER:
					field_data.sorter = (a: any, b: any) => {
						const first = parseInt(a[fieldName])
							? parseInt(a[fieldName])
							: 0;
						const second = parseInt(b[fieldName])
							? parseInt(b[fieldName])
							: 0;
						return first - second;
					};

					break;
			}
		}
		return field_data;
	};

	const getFieldDataBySchema = (schema: CustomItemTypeField) => {
		const schemaUUID = schema?.uuid as keyof typeof PREDEFINED_SCHEMA;
		const predefinedField = PREDEFINED_SCHEMA[schemaUUID];
		const field_width =
			schema.type === SchemaFieldType.MULTIPLE_LINE
				? "L_COLUMN_WIDTH"
				: COLUMN_FIELDS[predefinedField]?.className || "M_COLUMN_WIDTH";

		const sorter =
			schema.type === SchemaFieldType.NUMBER
				? (a: Item, b: Item) =>
						+(a.type_data?.[schemaUUID] ?? 0) -
						+(b.type_data?.[schemaUUID] ?? 0)
				: (a: Item, b: Item) => {
						const first = (a.type_data?.[schemaUUID] ||
							"") as string;
						const second = (b.type_data?.[schemaUUID] ||
							"") as string;
						return first.localeCompare(second);
				  };

		return {
			className: column_styles[field_width],
			title: schema?.label,
			dataIndex: "type_data",
			key: schema.uuid,
			render: (type_data) => type_data[schema.uuid],
			sorter,
		} as ColumnProps<Item>;
	};

	const renderCheckbox = () => {
		const selectedAllItems = selectedItemIDs.length === items.length;
		return (
			<div className={styles.checkboxTable}>
				<CheckboxV2
					onChange={() =>
						setSelectedItemIDs(
							selectedAllItems ? [] : items.map((item) => item.id)
						)
					}
					value={
						selectedAllItems
							? true
							: selectedItemIDs.length > 0
							? "complicated"
							: false
					}
				/>
			</div>
		);
	};

	const columns: ColumnProps<Item>[] = [
		{
			className: column_styles.BOOK_MARK_COLUMN_WIDTH,
			title: renderCheckbox,
			dataIndex: "id",
			key: "checkbox",
			render: (id, item) => {
				const onChange = () => {
					if (selectedItemIDs.includes(item.id)) {
						setSelectedItemIDs(
							selectedItemIDs.filter((key) => item.id !== key)
						);
					} else {
						setSelectedItemIDs([...selectedItemIDs, item.id]);
					}
				};
				return (
					<CheckboxV2
						onChange={onChange}
						value={!!selectedItemIDs.includes(item.id)}
					/>
				);
			},
			fixed: "left",
			width: 56,
			onCellClick: (_: Item, e: Event) => e.stopPropagation(),
		},
		{
			width: 120,
			title: "ID",
			dataIndex: "custom_id",
			key: "custom_id",
			fixed: "left",
			render: (id, item) => {
				return (
					<div className={styles.bookmarkWrapper}>
						{item.is_bookmarked && (
							<GenemodIcon
								name="bookmark-freezer"
								className={cn(styles.bookmarkIcon, {
									"genemod-darkmode":
										theme === THEME_PREFERENCE.dark,
								})}
							/>
						)}
						<Typography variant="body" color="text-secondary-v2">
							{formatCustomId(item.organization_prefix, id)}
						</Typography>
					</div>
				);
			},
			sorter: (a, b) => a.custom_id - b.custom_id,
		},
	];
	/** Extra columns depends on itemType and user Preferences */
	const extraColumns: ColumnProps<Item>[] = useMemo(() => {
		if (!searchSettings || !item_group || (!isItemTypeLoading && !itemType))
			return [];

		const tableColumns = searchSettings[item_group.item_type];
		return tableColumns.column_order
			.split(",")
			.filter((columnName) => !!tableColumns[columnName])
			.map((columnName) => {
				if (COLUMN_FIELDS[columnName as keyof typeof COLUMN_FIELDS]) {
					return getFieldDataByFieldName(
						columnName as keyof typeof COLUMN_FIELDS
					);
				}
				const schema = itemType?.schema?.find(
					(schema) => schema.uuid === columnName
				);
				if (schema) {
					return getFieldDataBySchema(schema);
				}
				return {};
			});
	}, [searchSettings, item_group, itemType]);

	const getTableHeader = () => {
		const deleting = selectedItemIDs.length && !viewOnly;
		return (
			<div className={styles.tableHeader}>
				{deleting ? (
					<div className={styles.toolbarContent}>
						<Typography variant="body" color="text-tertiary-v2">
							{selectedItemIDs.length + " selected:"}
						</Typography>
						<div
							style={{
								display: "flex",
								gap: "16px",
								alignItems: "center",
							}}
						>
							<ButtonV2
								type="link"
								danger
								disabled={loading}
								onClick={() => {
									setLoading(true);
									handleArchiveOrRestoreButton(
										true,
										{
											items: items.filter((x) =>
												selectedItemIDs.includes(x.id)
											),
											layer: "ITEM",
										},
										() => {
											setLoading(false);
											setSelectedItemIDs([]);
										}
									);
									deleteParam("item_id");
								}}
							>
								Archive selected
							</ButtonV2>
						</div>
					</div>
				) : (
					!viewOnly && (
						<ButtonV2 icon="plus-circle" onClick={onAddItem}>
							Create new item
						</ButtonV2>
					)
				)}
			</div>
		);
	};

	const onRow = (record: Item) => ({
		onClick: () => {
			if (itemId === record.id) {
				setItemId(null);
			} else {
				setItemId(record.id);
			}
		},
	});

	const rowClassName = (record: Item) => {
		if (record.id === itemId) {
			return styles.selectedRow;
		} else if (selectedItemIDs.includes(record.id)) {
			return styles.selectedMultiRow;
		}
		return "";
	};

	/**
	 * render add new Item view (only render if there is no data)
	 */
	const renderAddNewItemView = (
		<div className={styles.addNewItemView}>
			<Typography color="text-secondary-v2">No items found.</Typography>
		</div>
	);

	return (
		<div className={styles.itemListTableWrapper}>
			<div className={styles.toolbarContainer}>{getTableHeader()}</div>
			<div className={styles.tableContainer}>
				<ResponsiveTable
					loading={loading}
					columns={!items.length ? [] : [...columns, ...extraColumns]}
					dataSource={items}
					className={styles.itemListTable}
					onRow={onRow}
					rowClassName={rowClassName}
					useLoadingSkeleton
				/>
				{!items.length && renderAddNewItemView}
			</div>
		</div>
	);
}
