import {
	GoogleDocCreateDto,
	GoogleDocCreateOptions,
	GoogleDocDto,
} from "@common/components/GoogleDocsIntegration/GoogleDrivePickerData";
import {
	AlertSearchParams,
	BaseAttachmentUploadData,
	CommentId,
	ConsumableAlertScopeSearchResult,
	ConsumableAlertSubtableResult,
	ConsumableAlertsCreateDto,
	ConsumableAlertsEditDto,
	ConsumableAlertsResult,
	ConsumableAlertsResultId,
	ConsumableSearchFilters,
	ConsumableSearchItem,
	ConsumableSearchSettings,
	ConsumableSearchSettingsColumn,
	ConsumableSearchSettingsDto,
	FreezerAlertBoxSearchQuery,
	FreezerAlertBoxSearchResult,
	FreezerAlertItemGroupSearchResult,
	FreezerAlertSubtableResult,
	FreezerAlertsCreateDto,
	FreezerAlertsEditDto,
	FreezerAlertsResult,
	FreezerAlertsResultId,
	FreezerOrder,
	FreezerOrderAttachment,
	FreezerOrderComment,
	FreezerOrderId,
	FreezerOrderSearchFilterState,
	FreezerOrderSearchResults,
	FreezerOrderSettings,
	FreezerOrderStatusHistory,
	FreezerSettings,
	FurnitureCategory,
	ItemAttachment,
	OrderFormData,
	PaginatedSearchResults,
	SearchSettings,
	WorkspaceId,
	FreezerOrderStatusId,
} from "@common/types";
import { AtLeastId } from "@helpers/TypeHelpers";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig } from "axios";

const consumableSearchSettingsFromDto = (
	settingsDto: ConsumableSearchSettingsDto
): ConsumableSearchSettings => {
	return {
		...settingsDto,
		column_order: settingsDto.column_order.split(
			","
		) as ConsumableSearchSettingsColumn[],
	};
};

const consumableSearchSettingsToDto = (
	settings: Partial<ConsumableSearchSettings>
): Partial<ConsumableSearchSettingsDto> => {
	return {
		...settings,
		...(!!settings.column_order && {
			column_order: settings.column_order.join(","),
		}),
	} as Partial<ConsumableSearchSettingsDto>;
};

type ConsumablesSearchPayload = {
	page: number;
	page_size: number;
	search: string;
};

type ItemGroupedByNameResults = {
	name: string;
	count: number;
};
type ItemGroupedByNameSearchParams = {
	search?: string;
	page?: number;
	page_size?: number;
	is_archived?: boolean;
	is_shared?: boolean;
};

type ExportOrdersParams = {
	status__in?: FreezerOrderStatusId[];
	is_archived?: "True" | "False" | undefined;
} & Partial<FreezerOrderSearchFilterState>;

const TAG_TYPES = [
	"ConsumableItem",
	"FreezerItem",
	"FreezerAlert",
	"ConsumableAlert",
	"Item",
	"ItemAttachment",
	"ItemType",
	"ItemTypes",
	"Box",
	"ItemGroup",
	"Freezer",
	"Shelf",
	"Rack",
	"Category",
	"FurnitureCategory",
	"Bookmark",
	"SearchSettings",
	"ItemOrder",
	"Consumable search columns",
	"ItemOrderSettings",
	"ItemOrderStatusHistory",
	"ItemOrderAttachment",
	"ItemOrderComment",
	"FreezerSettings",
] as const;

const withFreezerBase = (url: string) => `/freezer/api/${url}`;
const withOrderBase = (url: string) => `/orders/${url}`;
export const freezerApi = createApi({
	reducerPath: "freezerApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: TAG_TYPES,
	endpoints: (builder) => {
		const googleDocsUrl = "google-docs";
		const getGoogleDocsUrl = (payload: GoogleDocCreateOptions) => {
			if (payload.parentType === "Item") {
				return `v2/items/${payload.itemId}/${googleDocsUrl}/`;
			} else {
				// This should be an invalid url
				return `${googleDocsUrl}`;
			}
		};
		const googleDocs = {
			googleDocCreate: builder.mutation<
				GoogleDocDto,
				{ dto: GoogleDocCreateDto } & GoogleDocCreateOptions
			>({
				query: (payload) => ({
					url: withFreezerBase(getGoogleDocsUrl(payload)),
					method: "POST",
					body: payload.dto,
				}),
				invalidatesTags: ["ItemAttachment"],
			}),
			itemGoogleDocs: builder.query<GoogleDocDto[], { itemId: number }>({
				query: ({ itemId }) => `v2/items/${itemId}/google-docs/`,
				providesTags: ["ItemAttachment"],
			}),
		};

		const search = {
			freezerItemGroupedByNameSearch: builder.query<
				PaginatedSearchResults<ItemGroupedByNameResults>,
				ItemGroupedByNameSearchParams
			>({
				query: (params) => ({
					url: withFreezerBase(`v2/items/grouped-name/`),
					method: "GET",
					params: params,
				}),
			}),
			consumableItemSearch: builder.query<
				PaginatedSearchResults<{
					name: string;
					count: number;
				}>,
				ConsumableSearchFilters
			>({
				query: (params) => ({
					url: withFreezerBase(
						`consumables/consumables/search/grouped-name/`
					),
					method: "GET",
					params: params,
				}),
				providesTags: ["ConsumableItem"],
			}),
			consumablesSearch: builder.query<
				PaginatedSearchResults<ConsumableSearchItem>,
				ConsumableSearchFilters
			>({
				query: (params) => ({
					url: withFreezerBase(`consumables/search/`),
					method: "GET",
					params: params,
				}),
				transformResponse: (
					res: PaginatedSearchResults<ConsumableSearchItem>
				) => {
					const result = { ...res };
					result.results = res.results.filter(
						(item) => !item.parent_space.is_locked
					);
					return result;
				},
				providesTags: ["ConsumableItem"],
			}),
			furnitureCategoriesSearch: builder.query<
				PaginatedSearchResults<ConsumableAlertScopeSearchResult>,
				ConsumablesSearchPayload & {
					stock_name: string;
					filter_from_repository?: boolean;
				}
			>({
				query: (params) => ({
					url: withFreezerBase("consumables/furniture-categories/"),
					method: "GET",
					params,
				}),
			}),
			getConsumableSearchSettings: builder.query<
				ConsumableSearchSettings,
				{ is_repository?: boolean }
			>({
				query: (params) => ({
					url: withFreezerBase(`consumables/consumable-columns/`),
					method: "GET",
					params,
				}),
				transformResponse: consumableSearchSettingsFromDto,
				providesTags: ["Consumable search columns"],
			}),
			patchConsumableSearchSettings: builder.mutation<
				ConsumableSearchSettings,
				{
					body: Partial<ConsumableSearchSettings>;
					params?: { is_repository?: boolean };
				}
			>({
				query: ({ body, params }) => ({
					url: withFreezerBase(`consumables/consumable-columns/`),
					method: "PATCH",
					body: consumableSearchSettingsToDto(body),
					params,
				}),
				transformResponse: consumableSearchSettingsFromDto,
				invalidatesTags: ["Consumable search columns"],
			}),
		} as const;

		const itemAttachment = {
			itemAttachments: builder.query<ItemAttachment[], number>({
				query: (itemId) => ({
					url: withFreezerBase(
						`v2/items/${itemId}/attachment-upload/`
					),
				}),
				providesTags: ["ItemAttachment", "Item"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			itemAttachmentCreate: builder.mutation<
				ItemAttachment,
				{ itemId: number; data: FormData; config?: AxiosRequestConfig }
			>({
				query: ({ itemId, data, config }) => ({
					url: withFreezerBase(
						`v2/items/${itemId}/attachment-upload/`
					),
					method: "POST",
					body: data,
					config,
				}),
				invalidatesTags: ["Item", "ItemAttachment"],
			}),
			itemAttachmentPatch: builder.mutation<
				ItemAttachment,
				AtLeastId<ItemAttachment>
			>({
				query: (attachment) => ({
					url: withFreezerBase(
						`v2/item-attachments/${attachment.id}/`
					),
					method: "PATCH",
					body: attachment,
				}),
				invalidatesTags: ["Item", "ItemAttachment"],
			}),
			itemAttachmentDelete: builder.mutation<void, number>({
				query: (attachmentId) => ({
					url: withFreezerBase(
						`v2/item-attachments/${attachmentId}/`
					),
					method: "DELETE",
				}),
				invalidatesTags: ["Item", "ItemAttachment"],
			}),
		};

		// Freezer search settings (column orders, grouped or ungrouped search)
		const searchSetting = {
			searchSettings: builder.query<SearchSettings, void>({
				query: () => ({
					url: withFreezerBase(`v2/search-settings/`),
					method: "GET",
				}),
				providesTags: ["SearchSettings", "ItemTypes", "ItemType"],
			}),
			patchSearchSettings: builder.mutation<
				SearchSettings,
				Partial<SearchSettings>
			>({
				query: (data) => ({
					url: withFreezerBase(`v2/search-settings/`),
					method: "PATCH",
					body: data,
				}),
				invalidatesTags: ["SearchSettings", "ItemTypes", "ItemType"],
			}),
		} as const;

		const consumable = {
			furnitureCategory: builder.query<FurnitureCategory, number>({
				query: (id) => ({
					url: withFreezerBase(
						`/consumables/furniture-categories/${id}/`
					),
				}),
				providesTags: ["FurnitureCategory"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
		} as const;

		const alertsBaseUrl = "v2/stock-alerts";
		const stockAlerts = {
			// ~~~~~~~~ COMMON ~~~~~~~~
			shouldShowEmptyAlertsImage: builder.query<boolean, void>({
				query: () => ({
					url: withFreezerBase(
						`${alertsBaseUrl}/should-show-empty-alerts-image/`
					),
				}),
				providesTags: ["ConsumableAlert", "FreezerAlert"],
			}),

			// ~~~~~~~~ FREEZER ~~~~~~~~
			freezerStockAlertsSearch: builder.query<
				PaginatedSearchResults<FreezerAlertsResult>,
				AlertSearchParams
			>({
				query: (params) => ({
					url: withFreezerBase(`${alertsBaseUrl}/freezer/?`),
					params,
				}),
				providesTags: ["FreezerAlert"],
			}),
			getFreezerStockAlert: builder.query<
				FreezerAlertsResult,
				FreezerAlertsResultId
			>({
				query: (id) => ({
					url: withFreezerBase(`${alertsBaseUrl}/freezer/${id}/`),
				}),
				providesTags: ["FreezerAlert"],
			}),
			createFreezerStockAlert: builder.mutation<
				FreezerAlertsResult,
				FreezerAlertsCreateDto
			>({
				query: (body) => ({
					url: withFreezerBase(`${alertsBaseUrl}/freezer/`),
					method: "POST",
					body,
				}),
				invalidatesTags: ["FreezerAlert"],
			}),
			editFreezerStockAlert: builder.mutation<
				FreezerAlertsResult,
				FreezerAlertsEditDto
			>({
				query: (body) => ({
					url: withFreezerBase(
						`${alertsBaseUrl}/freezer/${body.id}/`
					),
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["FreezerAlert"],
			}),
			deleteFreezerStockAlert: builder.mutation<
				void,
				FreezerAlertsResultId
			>({
				query: (id) => ({
					url: withFreezerBase(`${alertsBaseUrl}/freezer/${id}/`),
					method: "DELETE",
				}),
				invalidatesTags: ["FreezerAlert"],
			}),
			searchBoxesStockAlerts: builder.query<
				PaginatedSearchResults<FreezerAlertBoxSearchResult>,
				FreezerAlertBoxSearchQuery
			>({
				query: (params) => ({
					url: withFreezerBase(`v2/box-search/`),
					params,
				}),
			}),
			searchItemGroupsStockAlerts: builder.query<
				PaginatedSearchResults<FreezerAlertItemGroupSearchResult>,
				FreezerAlertBoxSearchQuery
			>({
				query: (params) => ({
					url: withFreezerBase(`v2/item-groups-search/`),
					params,
				}),
			}),
			freezerStockAlertsSubtable: builder.query<
				FreezerAlertSubtableResult[],
				string
			>({
				query: (name) => ({
					url: withFreezerBase(`${alertsBaseUrl}/freezer/sub-data/`),
					params: {
						stock_name: name,
					},
				}),
			}),

			// ~~~~~~~~ CONSUMABLE ~~~~~~~~
			consumableStockAlertsSearch: builder.query<
				PaginatedSearchResults<ConsumableAlertsResult>,
				AlertSearchParams
			>({
				query: (params) => ({
					url: withFreezerBase(`${alertsBaseUrl}/consumables/`),
					params,
				}),
				providesTags: ["ConsumableAlert"],
			}),
			getConsumableStockAlert: builder.query<
				ConsumableAlertsResult,
				ConsumableAlertsResultId
			>({
				query: (id) => ({
					url: withFreezerBase(`${alertsBaseUrl}/consumables/${id}/`),
				}),
				providesTags: ["ConsumableAlert"],
			}),
			createConsumableStockAlert: builder.mutation<
				ConsumableAlertsResult,
				ConsumableAlertsCreateDto
			>({
				query: (body) => ({
					url: withFreezerBase(`${alertsBaseUrl}/consumables/`),
					method: "POST",
					body,
				}),
				invalidatesTags: ["ConsumableAlert"],
			}),
			editConsumableStockAlert: builder.mutation<
				ConsumableAlertsResult,
				ConsumableAlertsEditDto
			>({
				query: (body) => ({
					url: withFreezerBase(
						`${alertsBaseUrl}/consumables/${body.id}/`
					),
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["ConsumableAlert"],
			}),
			deleteConsumableStockAlert: builder.mutation<
				void,
				ConsumableAlertsResultId
			>({
				query: (id) => ({
					url: withFreezerBase(`${alertsBaseUrl}/consumables/${id}/`),
					method: "DELETE",
				}),
				invalidatesTags: ["ConsumableAlert"],
			}),

			consumableStockAlertsSubtable: builder.query<
				ConsumableAlertSubtableResult[],
				string
			>({
				query: (name) => ({
					url: withFreezerBase(
						`${alertsBaseUrl}/consumables/sub-data/`
					),
					params: {
						stock_name: name,
					},
				}),
			}),
		};

		const createItemDropdown = {
			dropdownOptions: builder.query<
				{ name: string; options: string[] },
				{ modelName: string; fieldName: string }
			>({
				query: ({ modelName, fieldName }) => ({
					url: withFreezerBase(
						`v2/dropdown-options/${modelName}-${fieldName}/`
					),
				}),
			}),
			addDropdownOption: builder.mutation<
				{ options: string[]; name: string },
				{ modelName: string; fieldName: string; options: string }
			>({
				query: ({ modelName, fieldName, ...body }) => ({
					url: withFreezerBase(
						`v2/dropdown-options/${modelName}-${fieldName}/`
					),
					method: "POST",
					body,
				}),
			}),
			deleteDropdownOption: builder.mutation<
				{ options?: string[] },
				{ modelName: string; fieldName: string; option: string }
			>({
				query: ({ modelName, fieldName, option }) => ({
					url: withFreezerBase(
						`v2/dropdown-options/${modelName}-${fieldName}/${option}/`
					),
					method: "DELETE",
				}),
			}),
		};
		const order = {
			ordersSearch: builder.query<
				FreezerOrderSearchResults,
				FreezerOrderSearchFilterState
			>({
				query: (params) => ({
					url: withOrderBase(`item-orders/`),
					params,
				}),
				providesTags: ["ItemOrder"],
				extraOptions: {
					maxRetries: 0,
				},
				transformResponse: (
					response: FreezerOrderSearchResults & {
						results: FreezerOrder[];
					}
				) => ({
					...response,
				}),
			}),
			order: builder.query<FreezerOrder, number>({
				query: (id) => ({
					url: withOrderBase(`item-orders/${id}/`),
				}),
				providesTags: ["ItemOrder"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			orderCreate: builder.mutation<FreezerOrder, OrderFormData>({
				query: (body) => ({
					url: withOrderBase(`item-orders/`),
					method: "POST",
					body,
				}),
				invalidatesTags: [
					"FreezerAlert",
					"ConsumableAlert",
					"ItemOrder",
				],
			}),
			orderPatch: builder.mutation<FreezerOrder, AtLeastId<FreezerOrder>>(
				{
					query: (order) => ({
						url: withOrderBase(`item-orders/${order.id}/`),
						method: "PATCH",
						body: order,
					}),
					invalidatesTags: ["ItemOrder", "ItemOrderStatusHistory"],
				}
			),
			orderDelete: builder.mutation<void, number>({
				query: (id) => ({
					url: withOrderBase(`item-orders/${id}/`),
					method: "DELETE",
				}),
				invalidatesTags: ["ItemOrder"],
			}),
			orderStatusSettings: builder.query<
				PaginatedSearchResults<FreezerOrderSettings>,
				WorkspaceId
			>({
				query: (workspaceId) => ({
					url: withOrderBase(`settings/?workspace=${workspaceId}`),
				}),
				providesTags: ["ItemOrderSettings"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			orderStatusSettingsPatch: builder.mutation<
				FreezerOrderSettings,
				FreezerOrderSettings
			>({
				query: ({ id, workspace: workspace_id, ...body }) => ({
					url: withOrderBase(
						`settings/${id}/?workspace=${workspace_id}`
					),
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["ItemOrderSettings"],
			}),
			orderStatusHistory: builder.query<
				PaginatedSearchResults<FreezerOrderStatusHistory>,
				FreezerOrderId
			>({
				query: (id) => ({
					url: `/orders/status-history/`,
					params: {
						order: id,
					},
				}),
				providesTags: ["ItemOrderStatusHistory"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			orderAttachments: builder.query<
				FreezerOrderAttachment[],
				FreezerOrderId
			>({
				query: (id) => ({
					url: withOrderBase(`item-orders/${id}/attachments/`),
				}),
				providesTags: ["ItemOrderAttachment"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			orderAttachmentsCreate: builder.mutation<
				FreezerOrderAttachment,
				BaseAttachmentUploadData & { orderId: FreezerOrderId }
			>({
				query: ({ orderId, config, data }) => ({
					url: withOrderBase(`item-orders/${orderId}/attachments/`),
					method: "POST",
					body: data,
					config,
				}),
				invalidatesTags: ["ItemOrderAttachment"],
			}),
			orderAttachmentsDelete: builder.mutation<void, number>({
				query: (attachmentId) => ({
					url: withOrderBase(`attachments/${attachmentId}/`),
					method: "DELETE",
				}),
				invalidatesTags: ["ItemOrderAttachment"],
			}),
			orderComments: builder.query<
				PaginatedSearchResults<FreezerOrderComment>,
				Partial<{
					order: FreezerOrderId;
				}> | void
			>({
				query: (params) => ({
					url: withOrderBase(`comments/`),
					params,
				}),
				providesTags: ["ItemOrderComment"],
				extraOptions: {
					maxRetries: 0,
				},
			}),
			orderCommentsCreate: builder.mutation<
				FreezerOrderComment,
				Pick<FreezerOrderComment, "order" | "body">
			>({
				query: (body) => ({
					url: withOrderBase(`comments/`),
					method: "POST",
					body,
				}),
				invalidatesTags: ["ItemOrderComment"],
			}),
			orderCommentsPatch: builder.mutation<
				FreezerOrderComment,
				Pick<FreezerOrderComment, "id" | "order" | "body">
			>({
				query: ({ id, order, ...body }) => ({
					url: withOrderBase(`comments/${id}/`),
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["ItemOrderComment"],
			}),
			orderCommentsDelete: builder.mutation<
				void,
				{ orderId: FreezerOrderId; commentId: CommentId }
			>({
				query: ({ orderId, commentId }) => ({
					url: withOrderBase(`comments/${commentId}/`),
					method: "DELETE",
				}),
				invalidatesTags: ["ItemOrderComment"],
			}),
			ordersExport: builder.query<string, ExportOrdersParams>({
				query: (params) => ({
					url: withOrderBase("item-orders/export/"),
					responseHandler: (response: any) => response.text(),
					params,
				}),
			}),
		} as const;

		const freezerSettings = {
			freezerSettings: builder.query<FreezerSettings, void>({
				query: () => ({
					url: withFreezerBase(`v2/freezer-settings/`),
				}),
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
			}),
		} as const;

		return {
			...search,
			...stockAlerts,
			...consumable,
			...googleDocs,
			...itemAttachment,
			...createItemDropdown,
			...searchSetting,
			...order,
			...freezerSettings,
		};
	},
});

export const {
	useFreezerItemGroupedByNameSearchQuery,
	useLazyFreezerItemGroupedByNameSearchQuery,
	useConsumableItemSearchQuery,
	useLazyConsumableItemSearchQuery,
	useConsumablesSearchQuery,
	useLazyConsumablesSearchQuery,
	useLazyFurnitureCategoriesSearchQuery,
	useFurnitureCategoriesSearchQuery,

	// ItemAttachment crud
	useLazyItemAttachmentsQuery,
	useItemAttachmentsQuery,
	useItemAttachmentCreateMutation,
	useItemAttachmentPatchMutation,
	useItemAttachmentDeleteMutation,

	// search settings
	useSearchSettingsQuery,
	usePatchSearchSettingsMutation,
	useGetConsumableSearchSettingsQuery,
	usePatchConsumableSearchSettingsMutation,

	useFurnitureCategoryQuery,
	// Stock alerts
	useShouldShowEmptyAlertsImageQuery,

	useFreezerStockAlertsSearchQuery,
	useGetFreezerStockAlertQuery,
	useCreateFreezerStockAlertMutation,
	useEditFreezerStockAlertMutation,
	useDeleteFreezerStockAlertMutation,
	useLazySearchBoxesStockAlertsQuery,
	useLazySearchItemGroupsStockAlertsQuery,
	useConsumableStockAlertsSearchQuery,
	useGetConsumableStockAlertQuery,
	useCreateConsumableStockAlertMutation,
	useEditConsumableStockAlertMutation,
	useDeleteConsumableStockAlertMutation,
	useConsumableStockAlertsSubtableQuery,
	useFreezerStockAlertsSubtableQuery,
	useSearchBoxesStockAlertsQuery,
	useSearchItemGroupsStockAlertsQuery,

	// Google docs
	useGoogleDocCreateMutation,

	useItemGoogleDocsQuery,

	// Dropdown
	useDropdownOptionsQuery,
	useLazyDropdownOptionsQuery,
	useAddDropdownOptionMutation,
	useDeleteDropdownOptionMutation,

	// order crud
	useOrdersSearchQuery: _useOrdersSearchQuery,
	useOrderQuery,
	useOrderCreateMutation,
	useOrderPatchMutation,
	useOrderDeleteMutation,

	useOrderStatusSettingsQuery,
	useOrderStatusSettingsPatchMutation,

	useOrderAttachmentsQuery,
	useLazyOrderAttachmentsQuery,
	useOrderAttachmentsCreateMutation,
	useOrderAttachmentsDeleteMutation,

	useOrderCommentsQuery,
	useOrderCommentsCreateMutation,
	useOrderCommentsDeleteMutation,
	useOrderCommentsPatchMutation,

	useOrderStatusHistoryQuery,
	useLazyOrdersExportQuery,

	useFreezerSettingsQuery,
} = freezerApi;
