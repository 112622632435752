import { TableItem } from "@common/types";
import { FreezerLayers } from "@containers/Freezer/Repository/components/FreezerTab";
import React, { useMemo } from "react";
import styles from "../Item.module.scss";
import cn from "classnames";
import { GenemodIcon, Typography } from "@common/components";

type Props = {
  item: TableItem;
  description?: string;
  selectedLayer: FreezerLayers;
  isItemAvailable?: boolean;
  onChooseItem?: (id: number, selectedItem: TableItem) => void;
  onSelectItem: (selectedItem: TableItem) => void;
  spacesAvailable?: number;
  showSpacesAvailable?: boolean;
}

const FreezerItem = ({
  item,
  description,
  onChooseItem,
  onSelectItem,
  isItemAvailable,
  selectedLayer,
  spacesAvailable,
  showSpacesAvailable
}: Props) => {
  const iconName = useMemo(() => {
    if (selectedLayer === "FREEZER") {
      return "freezer";
    }
    if (selectedLayer === "SHELF") {
      return "shelf";
    }
    if (selectedLayer === "RACK") {
      return "rack-grid";
    }
    if (selectedLayer === "CATEGORY") {
      return "category";
    }
    // if (selectedLayer === "ITEMGROUP") {
    //   return "itemgroup";
    // }
    return "closed-box-outline";
  }, [selectedLayer]);
  return (
    <div
      className={cn(styles.itemContainer, { [styles.itemContainerDisabled]: showSpacesAvailable && !isItemAvailable })}
      onClick={() => {
        if (showSpacesAvailable) {
          if (!isItemAvailable || !onChooseItem) return;
          return onChooseItem(item.id, item)
        }
        onSelectItem(item);
      }}
    >
      <div className={styles.itemContentContainer}>
        <GenemodIcon name={iconName} color="text-secondary-v2" />
        <div>
          <Typography className={styles.textHeight}>
            {item.name}
          </Typography>
          {description?.length ? (
            <Typography
              color="text-tertiary"
              className={styles.textHeight}
            >
              {description}
            </Typography>
          ) : null}
        </div>
      </div>
      {showSpacesAvailable ? (
        <>
          <Typography color="text-tertiary" variant="caption" className={cn(styles.spacesAvailable, { [styles.spacesAvailableCanHide]: isItemAvailable })}>
            {spacesAvailable}
            {" slots available"}
          </Typography>
          <Typography color="action-link" variant="caption" className={cn(styles.selectText, { [styles.selectTextAvailable]: isItemAvailable })}>Select</Typography>
        </>
      ) : (
        <GenemodIcon name="chevron-right" color="text-secondary-v2" />
      )}
    </div>
  );
};

export default React.memo(FreezerItem);
