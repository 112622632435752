import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";
import LayerSystemContainer from "@common/components/LayerSystemContainer/LayerSystemContainer";
import { FAILED_UPLOAD_MESSAGE } from "@common/constants";
import {
	API,
	ClickToEdit,
	LoadingSpinner,
	Notification,
	Typography,
	axios,
} from "@components";
import {
	DataGenemodImage,
	ImageNode,
} from "@components/Editor/formats/ImageNode";
import { PM_NAME_CHAR_LIMITS } from "@containers/ProjectManagement/data";
import cn from "classnames";
import _ from "lodash";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Editor, Path, Range, Text, Transforms } from "slate";
import { Editable, RenderElementProps, RenderLeafProps } from "slate-react";
import { CursorEditor } from "slate-yjs";
import HangingToolbar from "../../formats/HangingToolbar/HangingToolbar";
import { HangingLinkToolbar } from "../../formats/Link";
import { EditorCommands } from "../../helpers/editorCommons";
import { ForceRenderElementsWrapper } from "../../plugins/withForceRenderElementsPlugin";
import { CustomEditor, RenderedEditor } from "../../types";
import ReviseHighlightedTextHangingToolbar from "../ReviseHighlightedTextHangingToolbar/ReviseHighlightedTextHangingToolbar";
import { TCursor, relativePositionToAbsolutePosition } from "./CursorHelpers";
import styles from "./index.module.scss";
import { useReactToPrint } from "react-to-print";

export const PM_EDITOR_ID = "pm-editor-content";

type EditorProps = {
	readOnly?: boolean;
	contentContainerClassName?: string;
	name?: string;
	onNameChange?: (name: string) => void;
};
export function ReadOnlyEditor(props: EditorProps) {
	const editor = useGenemodSlate();
	return <InternalEditor {...props} editor={editor} />;
}

const REGEX_NO_FILE_EXT = /\.[^/.]+$/;

/**
 * Custom implementation of useCursors that only updates the state when needed.
 * For other use cases, useCursors from slate-yjs should work fine.
 * https://github.com/BitPhinix/slate-yjs/blob/main/src/plugin/useCursors.ts
 */
function useCursors(editor: Editor & CursorEditor) {
	const cursorRef = useRef<TCursor[]>([]);
	const [cursors, setCursorData] = useState<TCursor[]>([]);

	useEffect(() => {
		editor.awareness.on("update", () => {
			const newCursorData = Array.from(editor.awareness.getStates())
				.filter(
					([clientId]) => clientId !== editor.sharedType.doc?.clientID
				)
				.map(([, awareness]) => {
					let anchor = null;
					let focus = null;

					if (awareness.anchor) {
						anchor = relativePositionToAbsolutePosition(
							editor.sharedType,
							awareness.anchor
						);
					}

					if (awareness.focus) {
						focus = relativePositionToAbsolutePosition(
							editor.sharedType,
							awareness.focus
						);
					}

					return { anchor, focus, data: awareness };
				})
				.filter((cursor) => cursor.anchor && cursor.focus);

			/**
			 * PM-781:
			 * When the user focuses their cursor on the document, their
			 * awareness state is updated, triggering this callback. This causes
			 * the decorate prop to change, causing the editor to re-render. This
			 * re-render contains the user's old selection which may no longer be relevant
			 * and can break some normalization behaviors.
			 */
			if (!_.isEqual(cursorRef.current, newCursorData)) {
				cursorRef.current = newCursorData as TCursor[];
				setCursorData(newCursorData as TCursor[]);
			}
		});
	}, [editor]);

	/**
	 * Rename decoration properties since they are too generic.
	 */
	const decorate = useCallback(
		([node, path]) => {
			const ranges: any[] = [];
			if (Text.isText(node) && cursors.length) {
				cursors.forEach((cursor) => {
					if (Range.includes(cursor as any, path)) {
						const { focus, anchor, data } = cursor;

						const isFocusNode = Path.equals(focus.path, path);
						const isAnchorNode = Path.equals(anchor.path, path);
						const isForward = Range.isForward({ anchor, focus });

						ranges.push({
							cursor: {
								data,
								isForward,
								isCursor: isFocusNode,
							},
							anchor: {
								path,
								// eslint-disable-next-line no-nested-ternary
								offset: isAnchorNode
									? anchor.offset
									: isForward
									? 0
									: node.text.length,
							},
							focus: {
								path,
								// eslint-disable-next-line no-nested-ternary
								offset: isFocusNode
									? focus.offset
									: isForward
									? node.text.length
									: 0,
							},
						});
					}
				});
			}

			return ranges;
		},
		[cursors]
	);
	return { decorate };
}

export function LiveEditor(props: EditorProps) {
	const editor = useGenemodSlate();
	const { decorate: cursorsDecorate } = useCursors(
		editor as unknown as CursorEditor
	);
	const decorate = useCallback(
		(props: any) => {
			return cursorsDecorate(props);
		},
		[cursorsDecorate]
	);
	return <InternalEditor {...props} editor={editor} decorate={decorate} />;
}

type InternalEditorProps = EditorProps & {
	editor: CustomEditor;
	decorate?: any;
};
/**
 * Text editor
 * @component
 * @param {Object} props
 * @param {Boolean} props.readOnly
 * @param {Experiment} props.experiment
 */
function InternalEditor(props: InternalEditorProps) {
	const contentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => contentRef.current,
		pageStyle: undefined,
	});
	const { readOnly, editor, decorate, contentContainerClassName } = props;

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if ((event.ctrlKey || event.metaKey) && event.key === "p") {
				event.preventDefault();
				handlePrint();
			}
		};
		window.addEventListener("keydown", onKeyDown);
		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, []);

	const isDragEventForImage = (e: React.DragEvent<HTMLDivElement>) => {
		const htmlText = e.dataTransfer.getData("text/html");
		const isDraggingFile =
			e.dataTransfer.files && e.dataTransfer.files.length > 0;
		return (
			isDraggingFile ||
			// for images from our docs
			htmlText.includes(`data-genemod-image="${DataGenemodImage}"`) ||
			// for images dragged from other websites
			htmlText.includes("<img")
		);
	};

	const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
		if (!editor.permissions.image) {
			return;
		}
		const htmlText = e.dataTransfer.getData("text/html");
		const files = e.dataTransfer.files;
		let errorMessage = "";
		if (e.target instanceof HTMLTableCellElement) {
			// not allow to insert new image to table cell
			errorMessage = "Cannot insert image here";
			e.preventDefault();
		} else if (files && files.length > 0) {
			// Dragging a file from local machine onto the document
			Object.keys(files).forEach((key) => {
				const file: File = files[key as any];
				const [type] = file.type.split("/");
				if (type === "image") {
					// only if dropped file is image
					uploadImage(file);
				} else {
					// errorMessage =
					// 	"Failed to upload the image. File type not supported.";
					uploadAttachment(file);
				}
			});
			e.preventDefault();
		} else if (htmlText) {
			// Dragging from an external website
			// if there is text/html type
			const imgSrcSplit = htmlText.split('src="');
			if (imgSrcSplit.length > 1) {
				e.preventDefault();
				// and there is image src
				let imgSrc = imgSrcSplit[1].split(`"`)[0];
				imgSrc = imgSrc.replace(/amp;/g, "");
				fetch(imgSrc)
					.then((res) => res.blob())
					.then((blob) => {
						uploadImage(blob);
					})
					.catch((e) => {
						console.log(e);
					});
			}
			// Else: We are dragging a file within the same document, do not call "preventDefault"!
		}
		if (errorMessage) {
			Notification.warning({ message: errorMessage });
		}
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		if (isDragEventForImage(e)) {
			handleImageDrop(e);
		} else if (!editor.permissions.text) {
			// prevent dragging text around
			e.preventDefault();
			e.stopPropagation();
		}
	};

	const style = useMemo(() => ({ paddingBottom: 360 }), []);

	/** upload img to AWS storage, and insert image to the experiment */
	const uploadImage = (uploadImg: File | Blob) => {
		const data = new FormData();
		data.append("upload", uploadImg);
		const route = API.pm
			.documents()
			.get(editor.documentId)
			.imageUpload()
			.getRoute();
		axios
			.post(route, data)
			.then((response) => {
				if (!("error" in response)) {
					const uploadUUID = response.data.uuid;
					ImageNode.insertNewImage(editor, uploadUUID);
				}
			})
			.catch(() =>
				Notification.warning({
					message:
						"Failed to upload the image. Try again or contact us if it continues.",
				})
			);
	};

	const getFileExtension = (fileName: string): string => {
		const fileExtension = fileName.split(".").pop();
		return fileExtension && fileExtension !== fileName
			? fileExtension.trim()
			: "";
	};

	const uploadAttachment = (uploadFile: File) => {
		const nodeID = editor.insertUploadFile(editor.documentId);
		if (!nodeID) {
			return;
		}

		const editorCommands = new EditorCommands();
		editorCommands.updateNodeById(editor, nodeID, {
			uploadId: "",
			fileName: uploadFile.name.replace(REGEX_NO_FILE_EXT, ""),
			fileSize: uploadFile.size,
			fileType: getFileExtension(uploadFile.name),
			isLoading: true,
		});

		const data = new FormData();
		data.append("upload", uploadFile);
		const route = API.pm
			.documents()
			.get(editor.documentId)
			.attachmentUpload()
			.getRoute();
		axios
			.post(route, data)
			.then((response) => {
				if (!("error" in response)) {
					editorCommands.updateNodeById(editor, nodeID, {
						uploadId: response.data.id,
						fileName: uploadFile.name.replace(
							REGEX_NO_FILE_EXT,
							""
						),
						fileType: response.data.file_type,
						fileSize: uploadFile.size,
					});
				}
			})
			.catch((error) => {
				editorCommands.updateNodeById(editor, nodeID, {
					uploadId: -1,
					fileName: uploadFile.name.replace(REGEX_NO_FILE_EXT, ""),
					fileType: getFileExtension(uploadFile.name),
					fileSize: uploadFile.size,
					isLoading: false,
					hasError: true,
				});

				let errorMessage = FAILED_UPLOAD_MESSAGE;

				if (error.response && error.response.data.upload) {
					errorMessage = error.response.data.upload[0];
				}

				Notification.warning({
					message: errorMessage,
				});
			});
	};

	editor.readOnly = readOnly || false;

	return (
		<LayerSystemContainer
			id="pm-editor-container"
			className={styles.editorContentContainer}
			onDropCapture={handleDrop}
			overrideLayer={2}
			wrapperRef={contentRef}
		>
			<div className={styles.editorGradient}>
				<div
					id={PM_EDITOR_ID}
					data-experiment={editor.experimentId}
					className={cn(
						styles.editorContent,
						contentContainerClassName
					)}
				>
					<EditorTitle
						{...props}
						isConnected={(editor as any).isConnected?.()}
					/>
					<div
						style={{
							maxWidth: "min(100% - 96px, 712px)",
							margin: "auto",
						}}
					>
						<div
							style={{
								width: "40px",
							}}
						>
							<LoadingSpinner
								loading={
									(editor as any).isConnected?.() === false
								}
							/>
						</div>
					</div>
					<ForceRenderElementsWrapper editor={editor}>
						<BaseEditor
							className={styles.editor}
							editor={editor}
							style={style}
							decorate={decorate}
						/>
						<HangingLinkToolbar />
						<HangingToolbar />
						<ReviseHighlightedTextHangingToolbar />
					</ForceRenderElementsWrapper>
				</div>
			</div>
		</LayerSystemContainer>
	);
}

type BaseEditorProps = {
	editor: RenderedEditor;
	className?: string;
	style?: React.CSSProperties;
	decorate?: any;
	onFocus?: React.FocusEventHandler<HTMLDivElement>;
	onBlur?: React.FocusEventHandler<HTMLDivElement>;
	isTextArea?: boolean;
};

/**
 * Wrapper around the <Editable /> component that adds props from the given Editor
 */
export const BaseEditor = ({
	editor,
	className,
	style,
	decorate,
	onFocus,
	onBlur: _onBlur,
}: BaseEditorProps): JSX.Element => {
	const onBlur = useCallback(
		(e) => {
			editor.onBlur(e);
			_onBlur?.(e);
		},
		[editor.onBlur, _onBlur]
	);

	const renderLeaf = useCallback(
		(props: RenderLeafProps) => {
			return editor.renderLeaves(props) as JSX.Element;
		},
		[editor.renderLeaves]
	);

	const renderElement = useCallback(
		(props: RenderElementProps) => {
			return editor.renderElements(props) as JSX.Element;
		},
		[editor.renderElements]
	);

	return (
		<Editable
			className={className}
			style={style}
			onChange={editor.onChange as any}
			renderElement={renderElement}
			renderLeaf={renderLeaf}
			onKeyDown={(e) => {
				if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
					e.preventDefault();
					// Get the first and last nodes in the document
					const [first] = Editor.nodes(editor, { at: [] });
					const [last] = Editor.nodes(editor, {
						at: [],
						reverse: true,
					});
					const start = Editor.start(editor, first[1]);
					const end = Editor.end(editor, last[1]);

					// Set the selection to cover the entire document
					Transforms.select(editor, { anchor: start, focus: end });
					return;
				}
				editor.onKeyDown(e);
			}}
			onBlur={onBlur}
			onFocus={onFocus}
			readOnly={editor.readOnly}
			decorate={decorate}
			placeholder={editor.renderPlaceholder ? " " : undefined}
			renderPlaceholder={editor.renderPlaceholder}
		/>
	);
};

type EditorTitleProps = {
	isConnected: boolean;
} & EditorProps;

/**
 * Title over the editor that can be edited
 */
const EditorTitle = ({
	name = "",
	onNameChange = () => {},
	readOnly,
	isConnected,
}: EditorTitleProps) => {
	const [nameState, setNameState] = useState(name);
	const [isEditing, setIsEditing] = useState(name === "Untitled");
	const hiddenHelperRef = useRef<HTMLDivElement>(null);

	/**
	 * When the user tries to enter a blank name, we want to revert back to the
	 * old name. However, the ClickToEdit component is not updated with the old
	 * name so we need to force a reset by changing the key.
	 */
	const [refreshKey, setRefreshKey] = useState(0);

	useEffect(() => {
		if (name === "Untitled") {
			setIsEditing(true);
		}
	}, [name]);

	let height = "auto";
	if (hiddenHelperRef.current) {
		const style = getComputedStyle(hiddenHelperRef.current);
		height = style.height;
	}

	useEffect(() => {
		if (!isEditing) {
			setNameState(name);
		}
	}, [name]);

	if (!name && readOnly) return null;

	return (
		<>
			<ClickToEdit
				dataCy="pm-editor-title"
				key={refreshKey}
				edit={isEditing}
				value={name}
				onComplete={(v) => {
					if (!v) {
						setNameState(name);
						setRefreshKey((k) => k + 1);
						return;
					}
					onNameChange(v);
				}}
				onChange={(e) => setNameState((e.target as any)?.value)}
				component="textarea"
				variant="editorTitle"
				className={cn(styles.editorTitle, styles.editor)}
				addConfirmAndCancel={false}
				hideInputBorder
				noHoverEffect
				adjustHeight
				stretchInput
				containerClassName={styles.editorTitleContainer}
				id="editable-editor-title"
				style={{
					// hide and no pointer events if isConnected is false
					pointerEvents: isConnected ? "auto" : "none",
				}}
				inputProps={{
					style: {
						height,
						width: "100%",
					},
				}}
				readOnly={readOnly || !onNameChange}
				onEditChange={setIsEditing}
				maxLength={PM_NAME_CHAR_LIMITS.EXPERIMENT}
			/>
			{/* Hidden helper to get the height of the title, hack to get single line height text area to work. */}
			<Typography
				className={cn(
					styles.editorTitle,
					styles.editor,
					styles.hiddenHelper
				)}
				ref={hiddenHelperRef}
				variant="editorTitle"
			>
				{nameState}
			</Typography>
		</>
	);
};
