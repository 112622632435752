import React, { useState } from "react";
import {
	DateStringDash,
	ISOString,
	CURRENCY_DEFAULT_ID,
	CURRENCY_TYPES,
	Consumable,
	ConsumableId,
	FurnitureCategoryId,
} from "@common/types";
import {
	CustomDatePickerV3,
	GenemodIcon,
	SelectV2,
	Typography,
} from "@components";
import { IdsInConstType } from "@helpers/TypeHelpers";
import { CollapsibleCreatePanel } from "@containers/Freezer/components/CollapsibleCreatePanel/CollapsibleCreatePanel";
import { FormItem } from "@containers/Freezer/FormItem/FormItem";
import { FormItemsSection } from "@containers/Freezer/FormItem/FormItemSection";
import TextArea from "@common/components/TextArea/TextArea";
import dayjs from "dayjs";
import {
	useConsumableCreateMutation,
	useConsumableRepositoryCreateMutation,
} from "@redux/freezer/ConsumableApiSlice";
import {
	CallbackSaveOptions,
	FormItemProviderStateWrapper,
	ExtraFormState,
} from "@helpers/Hooks/UseFormStateHook";
import styles from "./ConsumableCreatePanel.module.scss";
import { useFurnitureCategoriesSearchQuery } from "@redux/freezer/FreezerApiSlice";
import { useDebounceEventHandler } from "@helpers/Hooks";
import InputV3 from "@common/components/InputV3/InputV3";
import InputNumberV2 from "@common/components/InputNumber/InputNumber";
import { CurrencyEditCell } from "@containers/ProjectManagement/components/ProjectViewV2/components/ExperimentFileMaterials/MaterialsTable";

type ConsumableCreatePanelProps = {
	furnitureCategoryId?: FurnitureCategoryId;
	setConsumableId: (id: ConsumableId) => void;
	setIsCreate: (isCreate: boolean) => void;
	isRepositoryPage?: boolean;
	children?: JSX.Element;
	extraPadding?: boolean;
};
export default function ConsumableCreatePanel({
	furnitureCategoryId,
	setConsumableId,
	setIsCreate,
	children,
	isRepositoryPage = false,
	extraPadding,
}: ConsumableCreatePanelProps) {
	const [defaultConsumable] = useState<Partial<Consumable>>({
		quantity: 1,
		created_at: dayjs().toISOString() as ISOString,
		added_at: dayjs().toISOString() as ISOString,
		currency: CURRENCY_DEFAULT_ID,
		price: "",
	});
	const [formData, setFormData] =
		useState<Partial<Consumable>>(defaultConsumable);
	const [category, setCategory] = useState<Consumable | null>(null);
	const [price, setPrice] = useState(defaultConsumable.price);
	const [currency, setCurrency] = useState<
		IdsInConstType<typeof CURRENCY_TYPES>
	>(defaultConsumable.currency as IdsInConstType<typeof CURRENCY_TYPES>);

	const afterCreate = (newConsumable: Consumable) => {
		setIsCreate(false);
		setConsumableId(newConsumable.id);
	};
	const [editingDatePicker, setEditingDatePicker] = useState({
		created_at: false,
		expiration_date: false,
	});

	const [postConsumable] = useConsumableCreateMutation();
	const [createRepositoryConsumable] =
		useConsumableRepositoryCreateMutation();

	const onCancel = () => {
		setIsCreate(false);
	};

	const handleFormData = (key: string, value: string | number) => {
		if (isRepositoryPage) {
			setFormData((oldData) => ({ ...oldData, [key]: value }));
		}
	};

	const handleSave = () => {
		if (category) {
			formData.parent_furniture_category = Number(
				category.id
			) as FurnitureCategoryId;
		}
		createRepositoryConsumable({ consumableData: formData })
			.unwrap()
			.then(afterCreate);
	};

	const isSubmitBtnDisabled =
		!formData?.name?.trim() ||
		isNaN(+(formData?.price?.trim?.() as string) as number) ||
		!formData.added_at;

	type ChildProps = {
		onClick?: () => void;
		disabled?: boolean;
	};
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement<ChildProps>(child)) {
			return React.cloneElement(child, {
				onClick: handleSave,
				disabled: isSubmitBtnDisabled,
			});
		}
		return child;
	});

	const getCustomFields = (
		getFormItem: FormItemProviderStateWrapper<Consumable>,
		extra: ExtraFormState<Consumable>
	) => {
		return [
			getFormItem("source", (newProps) => (
				<FormItem label="Source">
					<InputV3
						maxLength={200}
						{...newProps}
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/>
				</FormItem>
			)),
			getFormItem("reference", (newProps) => (
				<FormItem label="Reference">
					<InputV3
						maxLength={200}
						{...newProps}
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/>
				</FormItem>
			)),
			getFormItem("catalog", (newProps) => (
				<FormItem label="Catalog #" width="FIRST_TWO_THIRDS">
					<InputV3
						maxLength={200}
						{...newProps}
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/>
				</FormItem>
			)),
			getFormItem("lot", (newProps) => (
				<FormItem label="Lot #" width="THIRD_THIRD">
					<InputV3
						maxLength={200}
						{...newProps}
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/>
				</FormItem>
			)),
			getFormItem("packaging", (newProps) => (
				<FormItem label="Packaging">
					<InputV3
						maxLength={200}
						{...newProps}
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/>
				</FormItem>
			)),
			getFormItem("price", (newProps) => (
				<FormItem label="Price" width="FULL">
					{/* <InputV3
						maxLength={200}
						{...newProps}
						prefix={
							CURRENCY_TYPES[
								extra.formState.currency || CURRENCY_DEFAULT_ID
							] + ""
						}
						required
						onChange={(e) => {
							handleFormData(newProps.key, e.target.value);
							newProps.onChange(e.target.value);
						}}
					/> */}
					<CurrencyEditCell
						currency={currency || 0}
						price={String(price) || ""}
						setCurrency={(e: any) => {
							setCurrency(
								e as IdsInConstType<typeof CURRENCY_TYPES>
							);
							handleFormData("currency", e);
							newProps.extra.onChange("currency", e);
						}}
						setPrice={(_price: any) => {
							setPrice(_price);
							handleFormData(newProps.key, _price);
							newProps.onChange(_price);
						}}
					/>
				</FormItem>
			)),
		];
	};

	const renderExtraForm = (
		getFormItem: FormItemProviderStateWrapper<Consumable>,
		extra: ExtraFormState<Consumable>
	) => {
		return (
			<>
				<FormItemsSection sectionTitle="Notes" key="notesCard">
					{getFormItem("notes", (newProps) => (
						<FormItem label="">
							<TextArea
								{...newProps}
								style={{ minHeight: 76 }}
								onChange={(e) =>
									newProps.onChange(e.target.value)
								}
								placeholder="Keep everyone on the same page..."
							/>
						</FormItem>
					))}
				</FormItemsSection>
				<FormItemsSection sectionTitle="Source" key="sourceCard">
					{getCustomFields(getFormItem, extra)}
				</FormItemsSection>
			</>
		);
	};

	const renderRepositoryExtraForm = (
		getFormItem: FormItemProviderStateWrapper<Consumable>,
		extra: ExtraFormState<Consumable>
	) => {
		return (
			<>
				<FormItemsSection
					sectionTitle="Advanced info"
					key="advancedInfo"
					defaultCollapsed
				>
					{getCustomFields(getFormItem, extra)}
					{getFormItem("notes", (newProps) => (
						<FormItem label="Notes">
							<TextArea
								{...newProps}
								style={{ minHeight: 76 }}
								onChange={(e) => {
									handleFormData(
										newProps.key,
										e.target.value
									);
									newProps.onChange(e.target.value);
								}}
								placeholder="Keep everyone on the same page..."
							/>
						</FormItem>
					))}
				</FormItemsSection>
			</>
		);
	};

	const renderLocationField = (
		getFormItem: FormItemProviderStateWrapper<Consumable>
	) => {
		return getFormItem("price", (newProps) => (
			<FormItem label="" width={"FULL"}>
				<CategorySelect category={category} setCategory={setCategory} />
			</FormItem>
		));
	};

	return (
		<>
			<CollapsibleCreatePanel<Consumable>
				title={"New item"}
				currentOrDefaultObject={defaultConsumable}
				nameEmpty={true}
				extraPadding={extraPadding}
				saveOptions={
					{
						onSave: (data: Partial<Consumable>) => {
							if (!isRepositoryPage && furnitureCategoryId) {
								postConsumable({
									furnitureCategoryId: furnitureCategoryId,
									consumableData: {
										...data,
										added_at: dayjs(
											data.added_at
										).toISOString() as ISOString,
									},
								})
									.unwrap()
									.then(afterCreate);
							}
						},
					} as CallbackSaveOptions<Consumable>
				}
				hideHeader={isRepositoryPage}
				onCancel={onCancel}
				getFormItems={(getFormItem, extra) => [
					<FormItemsSection
						sectionTitle={
							isRepositoryPage ? undefined : "General information"
						}
						key="generalInfoCard"
					>
						{[
							getFormItem("name", (newProps) => (
								<FormItem
									label="Item Name"
									required
									width={"FULL"}
								>
									<InputV3
										maxLength={200}
										required
										{...newProps}
										onChange={(e) => {
											handleFormData(
												newProps.key,
												e.target.value
											);
											newProps.onChange(e.target.value);
											newProps.onValidityChange(
												!!e.target.value.length
											);
										}}
										autoFocus
									/>
								</FormItem>
							)),
							getFormItem("quantity", (newProps) => (
								<FormItem label="Quantity" width={"FIRST_HALF"}>
									<InputNumberV2
										type="number"
										{...newProps}
										value={
											newProps.value !== undefined
												? newProps.value
												: ""
										}
										onChange={(e) => {
											const val = String(e);
											handleFormData(
												newProps.key,
												String(
													val === ""
														? undefined
														: +val
												)
											);
											newProps.onChange(
												val === "" ? undefined : +val
											);
										}}
										wrapperProps={{
											style: {
												width: "100%",
											},
										}}
										inputProps={{
											className: styles.inputWidth,
										}}
									/>
								</FormItem>
							)),
							getFormItem("unit", (newProps) => (
								<FormItem label="Unit" width={"SECOND_HALF"}>
									<InputV3
										type="string"
										{...newProps}
										value={
											newProps.value !== undefined
												? newProps.value
												: ""
										}
										onChange={(e) => {
											handleFormData(
												newProps.key,
												e.target.value
											);
											newProps.onChange(e.target.value);
										}}
									/>
								</FormItem>
							)),
							getFormItem("added_at", (newProps) => (
								<FormItem
									label="Created on"
									width={"FIRST_HALF"}
								>
									<CustomDatePickerV3
										{...newProps}
										value={
											newProps.value
												? dayjs(newProps.value)
												: null
										}
										autoFocus={false}
										showTime={false}
										open={editingDatePicker.created_at}
										onChange={(value) => {
											const fieldValue =
												(value?.toISOString() ||
													"") as ISOString;
											handleFormData(
												newProps.key,
												fieldValue
											);
											newProps.onChange(fieldValue);
										}}
										onOpenChange={(val) =>
											setEditingDatePicker({
												...editingDatePicker,
												created_at: val,
											})
										}
									/>
								</FormItem>
							)),
							getFormItem("expiration_date", (newProps) => (
								<FormItem
									label="Expires on"
									width={"SECOND_HALF"}
								>
									<CustomDatePickerV3
										{...newProps}
										value={
											newProps.value
												? dayjs(newProps.value)
												: null
										}
										autoFocus={false}
										showTime={false}
										open={editingDatePicker.expiration_date}
										onChange={(value) => {
											const fieldValue = (value?.format(
												"YYYY-MM-DD"
											) || "") as DateStringDash;
											handleFormData(
												newProps.key,
												fieldValue
											);
											newProps.onChange(fieldValue);
										}}
										onOpenChange={(val) =>
											setEditingDatePicker({
												...editingDatePicker,
												expiration_date: val,
											})
										}
									/>
								</FormItem>
							)),
							isRepositoryPage &&
								renderLocationField(getFormItem),
						]}
					</FormItemsSection>,
					isRepositoryPage
						? renderRepositoryExtraForm(getFormItem, extra)
						: renderExtraForm(getFormItem, extra),
				]}
			/>
			{childrenWithProps}
		</>
	);
}

function CategorySelect({
	category,
	setCategory,
}: {
	category: Consumable | null;
	setCategory: (value: Consumable | null) => void;
}) {
	const [categorySearch, setCategorySearch] = useState<string>("");
	// const { setHasErrors } = useContext(ErrorsContext);
	const onSetConsumableSearch = useDebounceEventHandler((value: string) => {
		setCategorySearch(value);
	}, 350);
	const { data: consumableSpaceData } = useFurnitureCategoriesSearchQuery(
		{
			page: 1,
			page_size: 10,
			stock_name: "",
			search: categorySearch,
			filter_from_repository: true,
		},
		{ refetchOnMountOrArgChange: true }
	);
	return (
		<>
			<div
				style={{
					marginTop: "24px",
					gridColumn: "first-half-start / second-half-end",
				}}
			>
				<Typography
					variant="label"
					bold
					style={{
						marginBottom: "8px",
						fontFamily: "var(--text-primary)",
					}}
				>
					Location
				</Typography>
				<SelectV2
					className={styles.locationSelect}
					allowClear
					clearIcon={
						<GenemodIcon
							name="exit"
							size="small"
							onClick={() => {}}
						/>
					}
					isInput
					showSearch
					value={category?.name || categorySearch || undefined}
					// handleFormData(newProps.key, e.target.value);
					onSelect={(n) => {
						const [idStr] = (n as string).split("-");
						const selectedId = parseInt(idStr, 10);
						const categoryFinded =
							consumableSpaceData?.results.find(
								(category) => category.id === selectedId
							);

						if (categoryFinded) {
							setCategory(categoryFinded as any as Consumable);
						}
					}}
					notFoundContent={
						<div>
							<span>Category not found</span>
						</div>
					}
					onSearch={(value) => onSetConsumableSearch(value)}
					placeholder="Search a category"
					dataCy="create-item-box-select"
				>
					{consumableSpaceData?.results.map((option) => {
						const { space, furniture } = option.path;
						return (
							<SelectV2.Option
								key={option.id}
								value={`${option.id}-${option.name}`}
								className={styles.boxSelectItem}
								style={{
									padding: "4px 16px",
								}}
							>
								<div>
									<div className={styles.boxNameContainer}>
										<span
											style={{
												color: "var(--text-secondary)",
											}}
										>
											{option.name}
										</span>
									</div>
									<Typography
										color="text-tertiary"
										variant="caption"
									>
										{`${space.name} > ${furniture.name}`}
									</Typography>
								</div>
							</SelectV2.Option>
						);
					})}
				</SelectV2>
			</div>
		</>
	);
}
