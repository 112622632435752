import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import {
	Path,
	Element,
	Editor,
	NodeEntry,
	Range,
	Transforms,
	Node,
} from "slate";
import { ReactEditor } from "slate-react";
import { CustomEditor } from "../types";
import { SimpleTableContext } from "./SimpleTable";
import { SimpleTableWrapper } from "./SimpleTableWrapper";
import { SimpleTableCell, SimpleTable } from "./types";

export const useSelection = (
	setSelectedCells: (val: Path[]) => void,
	cellPath: Path,
	editor: CustomEditor,
	element: SimpleTableCell
) => {
	const {
		dragging,
		resizing,
		setDragging,
		anchorCell,
		setAnchorCell,
		dragDirection,
		setDragDirection,
		setCopyPasteDragDirection,
		isButtonDragging,
		setIsButtonDragging,
		selectedCells,
	} = useContext(SimpleTableContext);

	// Handle when dragging starts
	const handleDragStart = (
		e: React.MouseEvent,
		assignValuesToCells = true
	) => {
		setDragging(true);
		setAnchorCell(cellPath); // Set the anchor (starting) cell
		if (assignValuesToCells) setSelectedCells([cellPath]); // Initially select the starting cell
		setDragDirection(null); // Reset direction at the start of the drag	};
		Transforms.select(editor, Editor.end(editor, cellPath));
	};

	// Handle dragging over other cells
	const handleDragOver = () => {
		if (!dragging || !anchorCell) return;

		const currentCellPath = ReactEditor.findPath(editor, element);

		let _dragDirection: "horizontal" | "vertical" | null = null;
		if (!dragDirection) {
			// Determine direction on first move
			if (currentCellPath[2] !== anchorCell[2]) {
				_dragDirection = "vertical";
				setDragDirection("vertical");
				setCopyPasteDragDirection("vertical");
			} else if (currentCellPath[3] !== anchorCell[3]) {
				_dragDirection = "horizontal";
				setDragDirection("horizontal");
				setCopyPasteDragDirection("horizontal");
			}
		}
		const selectedColumns = Array.from(
			new Set(selectedCells.map((cell) => cell[3]))
		);
		const selectedRows = Array.from(
			new Set(selectedCells.map((cell) => cell[2]))
		);

		if (isButtonDragging) {
			const startRow = Math.min(anchorCell[2], currentCellPath[2]);
			const endRow = Math.max(anchorCell[2], currentCellPath[2]);
			let selected = [...selectedCells];
			const selectedRowsSorted = selectedRows.sort((a, b) => a - b);
			const lastSelectedRow =
				selectedRowsSorted[selectedRowsSorted.length - 1];

			// Vertical movement
			if (currentCellPath[2] !== anchorCell[2]) {
				for (let r = startRow; r <= endRow; r++) {
					selectedColumns.forEach((col) => {
						selected.push([...cellPath.slice(0, 2), r, col]);
					});
					selected.push([...cellPath.slice(0, 2), r, anchorCell[3]]);
				}
				if (currentCellPath[2] < lastSelectedRow) {
					selected = selected.filter(
						(cell) => cell[2] !== lastSelectedRow
					);
				}
				setSelectedCells(selected);
				// Horizontal movement
			} else if (currentCellPath[3] !== anchorCell[3]) {
				const startCol = Math.min(anchorCell[3], currentCellPath[3]);
				const endCol = Math.max(anchorCell[3], currentCellPath[3]);
				let selected: Path[] = [...selectedCells];
				const selectedColumnsSorted = selectedColumns.sort(
					(a, b) => a - b
				);
				const lastSelectedColumn =
					selectedColumnsSorted[selectedColumnsSorted.length - 1];
				selectedRows.forEach((row) => {
					for (let c = startCol; c <= endCol; c++) {
						selected.push([...cellPath.slice(0, 2), row, c]);
					}
				});
				if (currentCellPath[3] < lastSelectedColumn) {
					selected = selected.filter(
						(cell) => cell[3] !== lastSelectedColumn
					);
				}
				setSelectedCells([...selected]);
			}
		} else {
			// If dragging is vertical, select cells in the same column
			if (_dragDirection === "vertical" || dragDirection === "vertical") {
				const startRow = Math.min(anchorCell[2], currentCellPath[2]);
				const endRow = Math.max(anchorCell[2], currentCellPath[2]);
				const selected = [];
				for (let r = startRow; r <= endRow; r++) {
					selected.push([...cellPath.slice(0, 2), r, anchorCell[3]]);
				}
				setSelectedCells(selected);
			}

			// If dragging is horizontal, select cells in the same row
			if (
				_dragDirection === "horizontal" ||
				dragDirection === "horizontal"
			) {
				const startCol = Math.min(anchorCell[3], currentCellPath[3]);
				const endCol = Math.max(anchorCell[3], currentCellPath[3]);
				const selected = [];
				for (let c = startCol; c <= endCol; c++) {
					selected.push([...cellPath.slice(0, 2), anchorCell[2], c]);
				}
				setSelectedCells(selected);
			}
		}
	};

	// Handle when dragging ends
	const handleDragEnd = () => {
		setDragging(false);
		setAnchorCell(null); // Clear anchor cell after selection
		setDragDirection(null); // Clear drag direction after selection	};
		setIsButtonDragging(false);
	};

	useEffect(() => {
		const handleMouseUpOutside = () => {
			handleDragEnd();
		};
		document.addEventListener("mouseup", handleMouseUpOutside);

		return () => {
			document.removeEventListener("mouseup", handleMouseUpOutside);
		};
	}, [dragging]);

	return { handleDragOver, handleDragEnd, handleDragStart };
};

export const useResizeButton = (
	editor: CustomEditor,
	columnWidth: number,
	setColumnWidth: Dispatch<SetStateAction<number>>,
	cellPath: Path
) => {
	const { setResizing, setSelectedCells } = useContext(SimpleTableContext);
	const handleMouseDown = (e: React.MouseEvent, column: number) => {
		const startX = e.clientX;
		e.stopPropagation();
		setSelectedCells([]);
		setResizing(column);

		const handleMouseMove = (moveEvent: MouseEvent) => {
			const newWidth = columnWidth + moveEvent.clientX - startX;
			setColumnWidth(newWidth);
			Transforms.setNodes(
				editor,
				{
					width: newWidth,
				},
				{
					at: cellPath,
				}
			);
		};

		const handleMouseUp = () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
			setResizing(null);
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	return { handleMouseDown };
};

export const useKeyboardListening = (
	editor: CustomEditor,
	tablePath: Path,
	selectedCells: Path[],
	setSelectedCells: (val: Path[]) => void,
	copyPasteDragDirection: "vertical" | "horizontal" | null,
	element: SimpleTableWrapper
) => {
	useEffect(() => {
		const listener = async (e: KeyboardEvent) => {
			const { selection } = editor;
			if (!selection) return;
			const [tableEntry] = Editor.nodes(editor, {
				match: (node) =>
					Element.isElement(node) &&
					node.type === "simple-table-wrapper" &&
					node.id === element.id,
				mode: "highest",
			});

			if (!tableEntry) return;
			const [, tablePath] = tableEntry as NodeEntry<SimpleTableWrapper>;
			const [start, end] = Editor.edges(editor, tablePath);
			const isTableSelected =
				Range.includes(selection, start) &&
				Range.includes(selection, end);
			if (e.key === "Backspace") {
				if (selectedCells.length > 1) {
					selectedCells.forEach((cellPath) => {
						Transforms.insertText(editor, "", { at: cellPath });
					});
				}
				if (isTableSelected) {
					Transforms.removeNodes(editor, { at: tablePath });
					e.preventDefault();
					e.stopPropagation();
				}
			}
			if (e.key === "ArrowUp" || e.key === "ArrowDown") {
				if (selectedCells.length === 1) {
					const cellPath = selectedCells[0];
					if (e.key === "ArrowDown") {
						// Increase by one the row of the selected cell
						cellPath[2] = cellPath[2] + 1;
					} else if (e.key === "ArrowUp") {
						cellPath[2] = cellPath[2] - 1;
					}
					Transforms.select(editor, cellPath);
					e.preventDefault();
					e.stopPropagation();
				}
			}
			if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
				const { selection } = editor;

				if (selection) {
					// Get the node that matches the 'table-cell' type in the current selection
					const [cell] = Editor.nodes(editor, {
						match: (node) =>
							Element.isElement(node) &&
							node.type === "simple-table-cell",
					});

					if (cell) {
						setSelectedCells([cell[1]]);
					}
				}
			} else if (
				(e.ctrlKey && e.key === "c") ||
				(e.metaKey && e.key === "c")
			) {
				const selectedNodes = Array.from(
					Editor.nodes(editor, { at: selection })
				);
				const firstElement = selectedNodes[1];
				// To check if the only thing selected is the table
				if (
					!firstElement ||
					!firstElement[0] ||
					!(firstElement[0] as any).type ||
					(firstElement[0] as any).type !== "simple-table-wrapper"
				) {
					return;
				} else {
					e.preventDefault();
				}

				if (!isTableSelected) {
					const cellsContenxt = selectedCells.map((cellPath) => {
						const [node] = Editor.node(editor, cellPath);
						return {
							content: Node.string(node),
							location: cellPath,
						};
					});
					navigator.clipboard.writeText(
						JSON.stringify(cellsContenxt)
					);
				} else {
					const tableNode = Editor.node(editor, tablePath)[0];
					navigator.clipboard.writeText(JSON.stringify(tableNode));
				}
			} else if (
				(e.ctrlKey && e.key === "x") ||
				(e.metaKey && e.key === "x")
			) {
				e.preventDefault();
				const cellsContenxt = selectedCells.map((cellPath) => {
					const [node] = Editor.node(editor, cellPath);
					Transforms.insertText(editor, "", {
						at: cellPath,
					});
					return {
						content: Node.string(node),
						location: cellPath,
					};
				});
				navigator.clipboard.writeText(JSON.stringify(cellsContenxt));
			} else if (
				(e.ctrlKey && e.key === "v") ||
				(e.metaKey && e.key === "v")
			) {
				e.preventDefault();
				const clipboardText = await navigator.clipboard.readText();
				let copiedCells: { content: string; location: Path }[];
				try {
					copiedCells = JSON.parse(clipboardText);
				} catch {
					copiedCells = [];
				}
				if (copiedCells.length === 1 && selectedCells.length === 1) {
					Transforms.insertText(editor, copiedCells[0].content, {
						at: selectedCells[0],
					});
				} else if (copiedCells.length > 1) {
					const columnsCount = editor.getSimpleTableColumnsCount();
					const rowsCount = editor.getSimpleTableRowsCount();
					const uniqueSelectedCells = Array.from(
						new Set(selectedCells)
					);
					const anchorCopiedCell = copiedCells[0].location;
					const anchorSelectedCell = uniqueSelectedCells[0];
					const rowsOffset =
						anchorSelectedCell[2] - anchorCopiedCell[2];
					const columnOffset =
						anchorSelectedCell[3] - anchorCopiedCell[3];
					copiedCells.forEach((cell) => {
						const location = cell.location;
						const row = location[2] + rowsOffset;
						const column = location[3] + columnOffset;
						if (
							column + 1 <= columnsCount &&
							row + 1 <= rowsCount
						) {
							Transforms.insertText(editor, cell.content, {
								at: [...location.slice(0, 2), row, column],
							});
						}
					});
				}
			} else if (
				(e.ctrlKey && e.key === "a") ||
				(e.metaKey && e.key === "a")
			) {
				e.preventDefault();
				const cellPath = selectedCells[0];

				if (cellPath) {
					const start = Editor.start(editor, cellPath); // Get the start of the cell
					const end = Editor.end(editor, cellPath); // Get the end of the cell

					// Create a range that selects from the start to the end of the cell
					Transforms.select(editor, { anchor: start, focus: end });

					// Ensure the editor is focused so the selection is visible
					ReactEditor.focus(editor);
				}
			} else if (e.key === "Tab") {
				e.preventDefault();
				const rowsCount = editor.getSimpleTableRowsCount();
				const columnsCount = editor.getSimpleTableColumnsCount();
				if (!selectedCells.length) return;
				if (selectedCells.length === 1) {
					const cell = [...selectedCells[0]];
					if (cell[3] + 2 <= columnsCount) {
						cell[3] = cell[3] + 1;
					} else {
						if (cell[2] + 2 > rowsCount) return;
						cell[2] = cell[2] + 1;
						cell[3] = 0;
					}
					Transforms.select(editor, {
						anchor: Editor.start(editor, cell),
						focus: Editor.end(editor, cell),
					});

					setSelectedCells([cell]);
				}
			} else if (e.key === "Enter") {
				e.preventDefault();
			}
		};

		window.addEventListener("keydown", listener);
		return () => {
			window.removeEventListener("keydown", listener);
		};
	}, [selectedCells, tablePath, editor.selection, copyPasteDragDirection]);
};

export const SimpleTableObj = {
	isInTable(editor: any) {
		return editor.isNodeOfTypeActive("simple-table");
	},
};
