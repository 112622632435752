import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./SimpleTable.module.scss";
import { Path } from "slate";
import { RenderElementPropsWithEditor } from "../TableV2/components";

type SimpleTableContextProps = {
	hoveredPosition: { col: number; row: number } | null;
	setHoveredPosition: (position: { col: number; row: number } | null) => void;
	resizeColumnHovered: number | null;
	setResizeColumnHovered: (col: number | null) => void;
	isAddRowButtonVisible: boolean;
	setIsAddRowButtonVisible: (val: boolean) => void;
	isAddColumnButtonVisible: boolean;
	setIsAddColumnButtonVisible: (val: boolean) => void;
	dragging: boolean;
	setDragging: Dispatch<SetStateAction<boolean>>;
	anchorCell: Path | null;
	setAnchorCell: Dispatch<SetStateAction<Path | null>>;
	dragDirection: "horizontal" | "vertical" | null;
	setDragDirection: Dispatch<
		SetStateAction<"horizontal" | "vertical" | null>
	>;
	copyPastedragDirection: "horizontal" | "vertical" | null;
	setCopyPasteDragDirection: Dispatch<
		SetStateAction<"horizontal" | "vertical" | null>
	>;
	selectedCells: Path[];
	setSelectedCells: (val: Path[]) => void;
	isButtonDragging: boolean;
	setIsButtonDragging: Dispatch<SetStateAction<boolean>>;
	resizing: number | null;
	setResizing: Dispatch<SetStateAction<number | null>>;
	tableSelected: boolean;
	setTableSelected: Dispatch<SetStateAction<boolean>>;
	wrapperSelected: boolean;
};
export const SimpleTableContext = React.createContext(
	{} as SimpleTableContextProps
);

const SimpleTable = ({ editor, ...props }: RenderElementPropsWithEditor) => {
	return (
		<table className={styles.simpleTable} {...props}>
			<tbody> {props.children}</tbody>
		</table>
	);
};

export default SimpleTable;
