import React, { useContext, useState } from "react";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { Notification } from "@components";
import { useCommonStateHook } from "@containers/Freezer/components/ItemViewAndEditPanel/ItemViewAndEditPanel";
import { useExportState } from "@containers/Freezer/hooks";
import { Box } from "@common/types";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { BoxActivityLogKeyContext, useBoxView } from "../../BoxTableHooks";
import BoxMovingCart from "@containers/Freezer/components/MovingCart/BoxMovingCart";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { useOrganizationRouter } from "@root/AppRouter";
import { truncArgs } from "@helpers/Formatters";
import { FREEZER_PATHS } from "@containers/Freezer";
import { useBoxTableActions } from "@redux/freezer/BoxTableSlice";
import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import BoxCategoryInfo from "@containers/Freezer/components/BoxCategoryInfo";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useBoxPatchMutation } from "@redux/inventory/Box";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function downloadTableAsPDF(tableId: string, title: string) {
	const table = document.getElementById(tableId);

	if (table) {
		// Capture the table as a canvas
		html2canvas(table)
			.then((canvas: HTMLCanvasElement) => {
				const imgData = canvas.toDataURL("image/png");

				// Define A4 dimensions in portrait mode and margins in pixels
				const pageWidth = 595; // A4 width in portrait (px at 72 dpi)
				const pageHeight = 842; // A4 height in portrait (px at 72 dpi)
				const margins = { top: 80, bottom: 40, left: 40, right: 40 };

				// Calculate the available space for the image
				const availableWidth = pageWidth - margins.left - margins.right;
				const availableHeight =
					pageHeight - margins.top - margins.bottom;

				// Scale the image to fit within the available space
				const scale = Math.min(
					availableWidth / canvas.width,
					availableHeight / canvas.height
				);
				const imgWidth = canvas.width * scale;
				const imgHeight = canvas.height * scale;

				// Initialize jsPDF in portrait orientation
				const pdf = new jsPDF({
					orientation: "portrait",
					unit: "px",
					format: [pageWidth, pageHeight],
				});

				// Add title at the top with specified margin
				pdf.setFontSize(16);
				pdf.text(title, margins.left, 60); // Adjust font size and position as needed

				// Add the image, centered horizontally and starting below the title
				const x = margins.left + (availableWidth - imgWidth) / 2;
				const y = margins.top;

				pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);

				// Save the PDF
				pdf.save(`${title}.pdf`);
			})
			.catch((error: any) => {
				console.error("Error capturing the table as PDF", error);
			});
	} else {
		console.error("Table element not found");
	}
}

const BoxInfo = (): JSX.Element => {
	const { setIsImporting } = useBoxTableActions();
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("boxSettings");

	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const { openDeleteBoxModal } = useCommonModalState("deleteBoxModal");

	const { box, viewOnly } = useBoxView();
	const [updateBox] = useBoxPatchMutation();
	const { handleExport, exportModal } = useExportState();

	const [moveBox, setMoveBox] = useState<number | null>(null);
	const [menuVisible, setMenu] = useState(false);

	const { formState, onCancel } = useCommonStateHook();

	const { openCustomizeBoxModal } = useCommonModalState("customizeBoxModal");

	const { activityLogKey } = useContext(BoxActivityLogKeyContext);

	const onSave = (payload?: Partial<Box>) => {
		if (!box || payload?.name === box?.name) return;
		if (!box || formState["name"] === box?.name) return;
		const name = (payload?.name || formState["name"]) as string;

		updateBox({
			...box,
			name: name.trim(),
		})
			.unwrap()
			.then(() => {
				onCancel();
			})
			.catch((err: Error) => {
				console.log("Failed to update a box", box, err);
			});
	};

	const handleDropdownSelection = (e: ClickParam) => {
		setMenu(false);
		if (!box) return;
		const key = e.key;
		if (key === "settings") {
			openPanel({ id: box.id });
		} else if (key === "delete") {
			openDeleteBoxModal({ id: box.id, navigateToParentRack: true });
		} else if (key === "import") {
			setIsImporting(true);
		} else if (key === "share") {
			openShareLinkModal({});
		} else if (key === "export") {
			handleExport("boxes", box.id, box.name);
		} else if (key === "save") {
			downloadTableAsPDF("box-grid", box.name);
		} else if (key === "move") {
			setMoveBox(box.id);
		} else if (key === "customize") {
			if (!box) return;
			openCustomizeBoxModal({ boxId: box?.id });
		}
	};

	const history = useHistory();
	const { appendBaseUrlAndParams } = useOrganizationRouter();
	const handleMovingCart = (boxData: Box) => {
		setMoveBox(null);
		const newLocation = appendBaseUrlAndParams(
			FREEZER_PATHS.CONTENTS.replace(
				":id",
				"" + boxData.location.freezer
			),
			{ selected_rack: boxData.location.rack_location?.rack || -1 }
		);
		history.push(newLocation);
		Notification.success({
			message:
				truncArgs`Moved "${boxData.name}" to "${boxData.path.rack.name}"`(
					20
				),
		});
	};

	const dropdownMenu = (
		<Menu onClick={handleDropdownSelection}>
			{!viewOnly && (
				<DropdownMenuItem key="move" label="Move to" icon="open-box" />
			)}
			<DropdownMenuItem
				key="customize"
				label="Customize box"
				icon="edit"
			/>
			<DropdownMenuDivider />
			<DropdownMenuItem
				key="import"
				label="Import items"
				icon="download"
			/>
			<DropdownMenuItem key="export" label="Export" icon="export" />
			<DropdownMenuItem key="save" label="Save as PDF" icon="printer" />
			<DropdownMenuDivider />
			<DropdownMenuItem key="settings" label="Settings" icon="settings" />
			<DropdownMenuItem key="share" label="Share" icon="share" />
			<DropdownMenuDivider />
			{!viewOnly && (
				<DropdownMenuItem
					key="delete"
					label="Delete box"
					icon="trash"
					color="dust-red"
				/>
			)}
		</Menu>
	);

	return (
		<>
			<BoxCategoryInfo
				dropdownMenu={dropdownMenu}
				onNameChange={(name) => onSave({ name })}
				setMenu={setMenu}
				menuVisible={menuVisible}
			/>
			{exportModal}
			<BoxMovingCart
				boxId={moveBox}
				onClose={() => setMoveBox(null)}
				onMoveBox={handleMovingCart}
			/>
		</>
	);
};

export default BoxInfo;
