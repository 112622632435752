import { IdsInConstType } from "@helpers/TypeHelpers";
import { Avatar, OrganizationUserId, UUID } from ".";
import { ISOString } from "./Date";
import { GenemodLinkType } from "./GenemodLink";

export const NOTIFICATION_SOURCE = [
	"EXPERIMENT_MENTION",
	"BOOKMARK_DELETED",
	"ITEM_CREATED",
	"ORDER_CREATED",
	"ORDER_COMPLETED",
	"ORDER_STATUS_CHANGED",
	"STOCK_ALERT_RUNNING_LOW",
	"PROJECT_ASSIGN",
	"CUSTOM_TEMPLATE",
	"FREEZER_ARCHIVED",
	"SPACE_ARCHIVED",
	"FREEZER_CREATED",
	"SPACE_CREATED",
	"PROTOCOL_CREATED",
	"ORDER_COMMENT",
	"EQUIPMENT_UNIT_SCHEDULED",
] as const;
export type NotificationSourceType = (typeof NOTIFICATION_SOURCE)[number];

export const isNotificationSourceType = (
	source: string
): source is NotificationSourceType => {
	return NOTIFICATION_SOURCE.includes(source as any);
};

export const MAP_NOTIFICATION_SOURCE_TO_LINK: {
	[source in NotificationSourceType]: GenemodLinkType;
} = {
	// Inventory
	FREEZER_CREATED: "FREEZER_CONTENTS",
	FREEZER_ARCHIVED: "FREEZER_CONTENTS",
	ITEM_CREATED: "FREEZER_ITEM",
	SPACE_ARCHIVED: "FREEZER_ARCHIVE",
	SPACE_CREATED: "FREEZER_SPACE",
	ORDER_COMPLETED: "FREEZER_ORDERS",
	ORDER_CREATED: "FREEZER_ORDERS",
	ORDER_STATUS_CHANGED: "FREEZER_ORDERS",
	ORDER_COMMENT: "FREEZER_ORDERS",
	BOOKMARK_DELETED: "none",
	CUSTOM_TEMPLATE: "FREEZER_ITEMTYPE",
	STOCK_ALERT_RUNNING_LOW: "FREEZER_STOCK_TRACKER",
	// ELN
	PROJECT_ASSIGN: "PM_PROJECT",
	EXPERIMENT_MENTION: "PM_FETCH_EXPERIMENT",
	PROTOCOL_CREATED: "PM_PROTOCOL",
	EQUIPMENT_UNIT_SCHEDULED: "EQUIPMENT_EQUIPMENT",
};

export const NotificationApps = Object.freeze({
	INVENTORY: 0,
	PROJECTS: 1,
	MENTIONS: 2,
	EQUIPMENTS: 3,
	0: "INVENTORY",
	1: "PROJECTS",
	2: "MENTIONS",
	3: "EQUIPMENTS",
} as const);

export const NotificationIdToLabel = Object.freeze({
	[NotificationApps.INVENTORY]: "Inventory",
	[NotificationApps.PROJECTS]: "Projects",
	[NotificationApps.MENTIONS]: "Mentions",
	[NotificationApps.EQUIPMENTS]: "Equipments",
} as const);

export type Notification = {
	id: number;
	object_id: number | UUID;
	object_name: string;
	description: string;
	created_at: ISOString;
	sender?: Avatar;
	receiver?: Avatar;
	read: boolean;
	notification_type: IdsInConstType<typeof NotificationApps>;
	source: string;
};

export type NotificationSettings = {
	org_user: OrganizationUserId;
	freezer_created: boolean;
	freezer_created_email: boolean;
	freezer_restored: boolean;
	freezer_restored_email: boolean;
	freezer_archived: boolean;
	freezer_archived_email: boolean;
	stock_running_low: boolean;
	stock_running_low_email: boolean;
	order_placed: boolean;
	order_placed_email: boolean;
	order_approved: boolean;
	order_approved_email: boolean;
	order_completed: boolean;
	order_completed_email: boolean;
	order_commented: boolean;
	order_commented_email: boolean;
	assigned_as_project_manager: boolean;
	assigned_as_project_manager_email: boolean;
	project_restored: boolean;
	project_restored_email: boolean;
	file_mention: boolean;
	file_mention_email: boolean;
	file_comment: boolean;
	file_comment_email: boolean;
};
