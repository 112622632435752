import { Consumable, Furniture, FurnitureCategory, Space, TableItem } from "@common/types";
import React, { useMemo } from "react";
import styles from "../Item.module.scss";
import cn from "classnames";
import { GenemodIcon, Typography } from "@common/components";
import { ConsumableLayers, ConsumableTableItems } from "@containers/Freezer/Repository/components/ConsumablesTab";

type Props = {
  item: ConsumableTableItems;
  description?: string;
  onChooseItem?: (id: number) => void;
  onSelectItem: (selectedItem: ConsumableTableItems) => void;
  showNextLayerIcon?: boolean;
}

const ConsumableItem = ({
  item,
  description,
  onChooseItem,
  onSelectItem,
  showNextLayerIcon
}: Props) => {
  return (
    <div
      className={cn(styles.itemContainer)}
      onClick={() => {
        if (!showNextLayerIcon) {
          if (!onChooseItem) return;
          return onChooseItem(item.id)
        }
        onSelectItem(item);
      }}
    >
      <div className={styles.itemContentContainer}>
        <div>
          <Typography className={styles.textHeight}>
            {item.name}
          </Typography>
          {description?.length ? (
            <Typography
              color="text-tertiary"
              className={styles.textHeight}
            >
              {description}
            </Typography>
          ) : null}
        </div>
      </div>
      {showNextLayerIcon ? (
        <GenemodIcon name="chevron-right" color="text-secondary-v2" />
      ) : (
        <Typography color="action-link" variant="caption" className={cn(styles.selectText, { [styles.selectTextAvailable]: !showNextLayerIcon })}>Select</Typography>
      )}
    </div>
  );
};

export default React.memo(ConsumableItem,);
