import React, { useContext, useState, useEffect, useRef } from "react";
import {
	Typography,
	SelectV2,
	DeleteFileModal,
	Notification,
	Input,
	GenemodIcon,
	ButtonV2,
	CustomDatePickerV3,
} from "@components";
import { useDefaultItemType } from "../data";
import {
	DEFAULT_ITEM_TYPE,
	CustomItemTypeField,
	FieldType,
	UUID,
	Item,
	ItemAttachment,
	NewAttachmentOrGoogle,
	CURRENCY_DEF,
	CURRENCY_TYPES,
	Box,
	ItemGroup,
	BoxLocationData,
	ITEM_STATUS,
} from "@common/types";
import dayjs from "dayjs";
import RenderReagentSpecificFields, {
	useCustomFormItemFields,
} from "../table/components/ReagentSpecificFields/ReagentSpecificFields";
import styles from "./CreateNewItem.module.scss";
import "./CreateNewItem.scss";
import { SECTION, CUSTOM_CONTENTS, FieldTypes } from "./data";
import {
	nameKeysOfConst,
	keysOfConst,
	IdsInConstType,
} from "@helpers/TypeHelpers";
import ItemTypeSelect from "@containers/Freezer/components/ItemTypeSelect/ItemTypeSelect";
import { itemTypeDataHasInvalidNumValue } from "@helpers/Validators";
import { AttachmentTable } from "@containers/Freezer/components/ItemViewAndEditPanel/ItemViewAndEditPanel";
import { ISOString } from "@common/types/Date";
import { TempAttachmentsContext } from "@containers/Freezer/BoxView/BoxView";
import { Menu } from "antd";
import { DropdownMenuItem } from "@common/components/DropDown/dropdown";
import {
	ENABLE_GOOGLE_DRIVE,
	useGooglePickerForDelayedCreate,
} from "@common/components/GoogleDocsIntegration/UseGooglePickerHook";
import GoogleDriveIcon from "@common/components/GoogleDocsIntegration/GoogleDriveIcon";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import cn from "classnames";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { Column, Row } from "@common/components/InteractiveGrid/GridTypes";
import LayerSystemContainer, {
	Layer,
} from "@common/components/LayerSystemContainer/LayerSystemContainer";
import { useItemTypesQuery } from "@redux/inventory/Item";
import {
	FormItemWithType,
	FormItemWithTypeProps,
} from "@containers/Freezer/FormItem/FormItem";
import { FormItemsSection } from "../FormItem/FormItemSection";
import { useSearchBoxesStockAlertsQuery } from "@redux/freezer/FreezerApiSlice";
import { useDebounceEventHandler } from "@helpers/Hooks";
import { ErrorsContext } from "../Repository/components/NewItemBtn/NewItemBtn";
import { useGetRepositoryBoxesQuery } from "@redux/inventory/Box";
import type { Dayjs } from "dayjs";
import { CurrencyEditCell } from "@containers/ProjectManagement/components/ProjectViewV2/components/ExperimentFileMaterials/MaterialsTable";
import { UploadAttachmentModalV2Content } from "@common/components/UploadAttachmentModalV2/UploadAttachmentModalV2";
import InputNumberV2 from "@common/components/InputNumber/InputNumber";
import { CollapsibleContainer } from "@containers/Freezer/components/CollapsibleContainer";
import { InfoSectionDivider } from "@containers/Freezer/table/components/CellInfo/CellInfo";

const MAX_LAYER_NAME = 30;

const getDefaultNewItem = (): Partial<Item> => ({
	id: -1,
	name: "",
	concentration: "",
	volume: "",
	expiration_date: null,
	added_at: dayjs().toISOString() as ISOString,
	notes: "",
	location: {
		freezer: -1,
		id: 0,
		box_location: {
			id: -1,
			column: -1 as Column,
			row: -1 as Row,
			box: 0,
		},
		item_group: null,
	},
	updated_at: "" as ISOString,
	item_type: DEFAULT_ITEM_TYPE,
	type_data: {},
	custom_id: -1,
	source: "",
	catalog: "",
	lot: "",
	packaging: "",
	price: "",
	reference: "",
	is_bookmarked: false,
	currency: CURRENCY_TYPES[CURRENCY_DEF],
});

type CreateNewItemProps = {
	title?: string;
	focusedCell: string | null;
	onCancel: () => void;
	presetFields?: Partial<Record<FieldTypes, Partial<FormItemWithTypeProps>>>;
	handleSubmit: (
		item: Item,
		callback?: (newBox: boolean, item: Item | Error) => void,
		copies?: number
	) => void;
	setIsFocused?: (v: boolean) => void;
	withoutHeader?: boolean;
	formSections?: any;
	dataFromFreezerView?: {
		setShouldBlock: (v: boolean) => void;
		box: Box | null | undefined;
		item_group: ItemGroup | null | undefined;
	};
	children?: JSX.Element;
	layerColor?: Layer;
	isCreatePanelVisible?: boolean;
	redirectTo?: string;
	isRepositoryPage?: boolean;
	isExpermentItemCreate?: boolean;
};

/**
 * The component to create new itme
 *
 * @param {Object} props
 * @param {String} props.boxId - the boxId which has this item
 * @param {Object} props.focusedCell - the location of selected cell
 * @param {Function} props.onCancel - the function to handle when cancel button clicked
 * @param {Object[]} props.presetFields - the item files which is preset
 * @param {Function} props.handleSubmit - the function called when createBtn is clicked: (Item) => {}
 * @param {Boolean} props.withoutHeader - it's used to render the component without header that include the title and the ok/cancel btn
 * @param {Object[]} props.formSections - the sections of the form with the fields that will be rendered
 * @param {Object} props.dataFromFreezerView - the data from freezer view, such as setShouldBlock, box, item_group
 * @param {JSX.Element} props.children - the children of the component
 * @param {Color} layerColor - the override color of the layers for the form
 * @param {string} redirectTo - the url to redirect after creating a new item type
 */
function CreateNewItem({
	title = "New item",
	focusedCell,
	onCancel,
	presetFields = {},
	handleSubmit,
	setIsFocused,
	withoutHeader = false,
	formSections = SECTION,
	dataFromFreezerView,
	children,
	layerColor,
	isCreatePanelVisible = false,
	redirectTo,
	isRepositoryPage = false,
	isExpermentItemCreate = false,
}: CreateNewItemProps): JSX.Element {
	const { Option } = SelectV2;

	const isCreatingFromFreezerView = !!dataFromFreezerView;
	// TODO: create a new item for item group
	const {
		setShouldBlock,
		box: boxFromFreezerView,
		item_group,
	} = dataFromFreezerView || {};

	const { defaultItemType, setLastSelectedItemType } = useDefaultItemType();

	const defaultValuesFromPresetFields = Object.entries(presetFields).reduce(
		(obj, [key, value]) => {
			if (value?.value) {
				return { ...obj, [key]: value.value };
			}
			return obj;
		},
		{}
	);

	const [item, setItem] = useState<Partial<Item>>({
		...getDefaultNewItem(),
		item_type: defaultItemType,
		...defaultValuesFromPresetFields,
	});

	const [isEmptyAllField, setIsEmptyAllField] = useState(true);
	const { data: itemTypes } = useItemTypesQuery();

	/** Upload/delete item attachment */
	const [attachmentDropVisible, setAttachmentDropVisible] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);

	const [uploadingRate, setUploadingRate] = useState(0.0);
	const [attachmentToDelete, setAttachmentToDelete] =
		useState<NewAttachmentOrGoogle | null>(null);
	const { currentUserAsTeamMember: user } = useCurrentTeamMembers();

	const { tempAttachments, setTempAttachments } = useContext(
		TempAttachmentsContext
	);
	const { is_limit_reached: isGoogleDriveRestricted } =
		useFeatureRestrictionHook("google_drive");
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const {
		openGooglePickerAndCreate,
		createDtos,
		deleteGoogleDoc,
		createAllGoogleDocs,
	} = useGooglePickerForDelayedCreate({
		parentType: "Item",
		itemId: item.id || 0,
	});
	const allAttachments = [...tempAttachments, ...createDtos];

	// Scroll into view after uploading
	const uploadButtonRef = useRef<HTMLButtonElement>(null);

	// Sets the additional type's fields using e.target.id to e.target.value
	const handleTypeDataChange = (e: {
		target: { id: UUID; value: string | string[] | undefined };
	}) => {
		const { id: uuid, value } = e.target;
		setItem((item) => ({
			...item,
			type_data: {
				...item.type_data,
				[uuid]: value || "",
			},
		}));
	};

	const { fields: custonFormItemFields } = useCustomFormItemFields(
		item as Item,
		FormItemWithType,
		handleTypeDataChange
	);
	const [box, setBox] = useState<Box | null>(boxFromFreezerView || null);
	const [amountOfCopies, setAmountOfCopies] = useState<number | undefined>(1);

	useEffect(() => {
		setTempAttachments([]);
	}, []);

	const selectionMarkerUuid =
		itemTypes
			?.reduce(
				(acc: CustomItemTypeField[], itemType) => [
					...acc,
					...(itemType?.schema || []),
				],
				[]
			)
			.find((field) => field.type === FieldType.SELECTION_MARKER)?.uuid ||
		"-1";

	const copyItem = (item: Partial<Item>) => ({
		...item,
		type_data: !item.type_data ? item.type_data : { ...item.type_data },
	});

	const clearFields = () => {
		setItem(getDefaultNewItem());
	};

	// create the item in the form
	const handleSubmitWrap = () => {
		setShouldBlock?.(false);
		if (!box && isCreatingFromFreezerView && !item_group) return;
		const copy = copyItem(item);
		if (!copy.location || !copy.location.box_location) return;
		// Set location and parent_box info
		if (box && isCreatingFromFreezerView) {
			copy.location.box_location.box = box.id;
			copy.location.freezer = box.location.freezer;

			if (focusedCell) {
				copy.location.box_location.row = (parseInt(
					focusedCell.substring(1)
				) - 1) as Row;
				copy.location.box_location.column = (focusedCell.charCodeAt(0) -
					65) as Column;
				copy.location.box_location.box = box.id;
				copy.location.item_group = null;
			}
		} else if (item_group) {
			copy.location.item_group = item_group.id;
			copy.location.box_location = null;
		} else if (box && !isCreatingFromFreezerView) {
			const freezerId = box.location ? box.location.freezer : null;
			copy.location = {
				freezer: freezerId,
				box_location: {
					box: box.id,
				},
				item_group: null,
			} as any;
		} else if (!box && !isCreatingFromFreezerView) {
			copy.location = undefined;
			copy.location_data = undefined;
		}

		// if no added on date was provided, it will automatically set it to today's date
		if (copy.added_at === "") {
			const today = dayjs();
			copy.added_at = today.toISOString() as ISOString;
		}

		if (Array.isArray(copy.type_data?.[selectionMarkerUuid])) {
			(copy.type_data as any)[selectionMarkerUuid] = (
				(copy.type_data as any)[selectionMarkerUuid] as string[]
			).join(", ");
		}
		handleSubmit(
			copy as Item,
			(_, item) =>
				"id" in item &&
				createAllGoogleDocs({
					parentType: "Item",
					itemId: item?.id,
				}),
			amountOfCopies
		);
		clearFields();
	};

	/**
	 * handle changed item field
	 *
	 * @param {*} e if there is id, then this (e) treat as value, otherwise set field with e.target.id,e.target.value
	 * @param {*} [id=null] the field id
	 */
	const handleFieldChange = (e: any, id: string | null = null) => {
		const fieldId = id ? id : e?.target?.id;
		const value = id ? e : e.target.value;
		if (fieldId === "expiration_date" || fieldId === "added_at") {
			const dateFormat = "MM/DD/YYYY";
			// If input date is not format of dateFormat, then, just ignore the change
			if (value && !dayjs(value, dateFormat, true).isValid()) {
				return;
			}
		}
		const temp = copyItem(item);
		(temp as any)[fieldId] = value;
		if (isEmptyAllField && isCreatePanelVisible) {
			setIsEmptyAllField(false);
			setShouldBlock?.(true);
			/** handle focused_cell in the box,
			 * so that move next cell if all field is empty with key,
			 * otherwise, move it to next formfield */
			setIsFocused?.(false);
		}
		setItem((item) => ({
			...copyItem(item),
			[fieldId]: value,
		}));
	};

	// handle to select itemType
	const handleReagentTypeChange = (type: number) => {
		setLastSelectedItemType(type);
		setItem((item) => ({
			...item,
			item_type: type,
			// Clears any pre-existing type data and sets it to an empty dict
			type_data: {},
		}));
	};

	const isSubmitBtnDisabled =
		!amountOfCopies ||
		amountOfCopies < 1 ||
		amountOfCopies > 20 ||
		!item?.name?.trim() ||
		isNaN(+(item?.price?.trim?.() as string) as number) ||
		!item.added_at ||
		itemTypeDataHasInvalidNumValue(
			item?.type_data,
			item?.item_type,
			itemTypes
		);

	const handleKeyDownSubmit = React.useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			if (event.key === "Enter") {
				handleSubmitWrap();
			}
		},
		[handleSubmitWrap]
	);

	const handleKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			if (event.key === "Enter") {
				setShouldBlock?.(false);
				onCancel();
			}
		},
		[setShouldBlock, onCancel]
	);

	//render Header part
	const renderHeader = (
		<div className={styles.header}>
			<Typography
				variant="headline4"
				ellipsis
				className={styles.itemName}
				medium
			>
				{title}
			</Typography>
			<div className={styles.formButtons}>
				<ButtonV2
					disabled={isSubmitBtnDisabled}
					onClick={handleSubmitWrap}
					type="primary"
					dataCy="new-item-create"
					onKeyDown={handleKeyDownSubmit}
				>
					Create item
				</ButtonV2>
			</div>
		</div>
	);

	const uploadCompleted = async (file: any) => {
		setShowUploadModal(true);
		const data = new FormData();
		data.append("upload", file);

		try {
			const tempAttachment = {
				id: Math.floor(Math.random() * 100),
				file_type: file.path.split(".").pop(),
				name: file.name,
				created_at: new Date().toISOString() as ISOString,
				created_by: user?.user?.avatar,
				upload: file.path,
				file: file,
				formData: data,
			} as ItemAttachment;

			const tempAttachmentsCopy = [...tempAttachments, tempAttachment];
			setTempAttachments(tempAttachmentsCopy);
			setShowUploadModal(false);
		} catch (error) {
			console.error("Fail to upload the attachment " + error);
		}
	};

	const [editingDatePicker, setEditingDatePicker] = useState({
		added_at: false,
		expiration_date: false,
	});

	//render content if type is custom
	const renderCustomContent = (contentType: keyof typeof CUSTOM_CONTENTS) => {
		switch (contentType) {
			case CUSTOM_CONTENTS.TYPE_SELECTOR:
				return (
					<ItemTypeSelect
						onSelect={(val) =>
							handleReagentTypeChange(val as number)
						}
						value={item.item_type as number}
						disabled={presetFields.itemType?.disabled}
						dataCy="new-item-type-select"
						redirectTo={redirectTo}
					/>
				);
			case CUSTOM_CONTENTS.DATEPICKET_ADDED_AT:
				return (
					<CustomDatePickerV3
						id="added_at"
						format="MM/DD/YYYY"
						placeholder="MM/DD/YYYY"
						autoFocus={false}
						showTime={false}
						value={item.added_at ? dayjs(item.added_at) : null}
						onChange={(val: Dayjs) => {
							handleFieldChange(val, "added_at");
							setEditingDatePicker({
								...editingDatePicker,
								added_at: false,
							});
						}}
						open={editingDatePicker.added_at}
						allowClear
						onOpenChange={(val) =>
							setEditingDatePicker({
								...editingDatePicker,
								added_at: val,
							})
						}
					/>
				);
			case CUSTOM_CONTENTS.DATEPICKER_EXPIRES:
				return (
					<CustomDatePickerV3
						id="expiration_date"
						autoFocus={false}
						showTime={false}
						format="MM/DD/YYYY"
						placeholder="MM/DD/YYYY"
						value={
							item.expiration_date
								? dayjs(item.expiration_date)
								: null
						}
						onChange={(val: Dayjs) => {
							handleFieldChange(val, "expiration_date");
							setEditingDatePicker({
								...editingDatePicker,
								expiration_date: false,
							});
						}}
						open={editingDatePicker.expiration_date}
						allowClear
						onOpenChange={(val) =>
							setEditingDatePicker({
								...editingDatePicker,
								expiration_date: val,
							})
						}
					/>
				);
			case CUSTOM_CONTENTS.PRICE:
				return (
					<CurrencyEditCell
						currency={item.currency || 0}
						price={String(item.price) || ""}
						setCurrency={(e: any) => {
							handleFieldChange(e as string, "currency");
						}}
						setPrice={(price: any) => {
							handleFieldChange(price as string, "price");
						}}
					/>
				);
			case CUSTOM_CONTENTS.ATTACHMENT:
				return renderAttachmentSection();
		}
		return null;
	};

	//render section items
	const renderSectionItems = (fields: any) => {
		/**
		 * DASH-2460
		 * The autofocus property is provided in both fields[id] and
		 * presetFields[id]. If the name field is disabled, another
		 * field will be autofocused via presetFields[id].
		 */
		return Object.keys(fields).map((id) => (
			<FormItemWithType
				{...fields[id]}
				key={id}
				label={fields[id].label}
				submitOnEnter={
					fields[id].submitOnEnter &&
					!isSubmitBtnDisabled &&
					handleSubmitWrap
				}
				id={id}
				onChange={handleFieldChange}
				value={(item as any)[id]}
				prefix={
					id === "price"
						? CURRENCY_TYPES[
								(isNaN(item.currency as number)
									? CURRENCY_TYPES[CURRENCY_DEF]
									: item.currency) as IdsInConstType<
									typeof CURRENCY_TYPES
								>
						  ]
						: null
				}
				{...presetFields[id as FieldTypes]}
				renderCustomContent={() =>
					renderCustomContent(fields[id].content)
				}
			/>
		));
	};

	const handleDeleteTempItemAttachment = async (
		attachment: NewAttachmentOrGoogle | null
	) => {
		if (!attachment || !attachmentToDelete) return;

		// Delete google doc attachment
		if (attachment?.document_type === "GoogleDocCreateDto") {
			deleteGoogleDoc(attachment);
			setAttachmentToDelete(null);
			return;
		}

		try {
			setTempAttachments(
				tempAttachments.filter((tempIA) => tempIA.id !== attachment.id)
			);
			setAttachmentToDelete(null);
		} catch (error) {
			Notification.warning({
				message:
					"Failed to delete the attachment. Try again or contact us if it continues.",
			});
		}
	};

	const renderAttachmentSection = () => (
		<div style={{ marginBottom: 24 }}>
			{allAttachments.length > 0 && (
				<AttachmentTable<NewAttachmentOrGoogle>
					attachments={allAttachments}
					viewOnly={false}
					isEditing={true}
					setAttachmentToDelete={setAttachmentToDelete}
					className={styles.attachmentTableCreation}
				/>
			)}
			<UploadAttachmentModalV2Content
				visible={showUploadModal}
				uploadCompleted={uploadCompleted}
				uploadingRate={uploadingRate}
				openGoogleDrive={
					isGoogleDriveRestricted
						? () =>
								openUpgradeModal({
									type: "GOOGLE_DRIVE",
								})
						: () => openGooglePickerAndCreate()
				}
				isGoogleDriveRestricted={isGoogleDriveRestricted}
			/>
		</div>
	);

	// render sections
	const renderSections = keysOfConst(formSections).map((section, idx) => {
		if (section === "SPECIFIC_FIELD") {
			return (
				<RenderReagentSpecificFields
					key={"section-" + idx}
					item={item as Item}
					FormItem={FormItemWithType}
					FormSection={FormItemsSection}
					handleTypeDataChange={handleTypeDataChange}
					defaultCollapsed={formSections[section].defaultCollapsed}
				/>
			);
		} else if (section === "ATTACHMENTS") {
			return (
				<FormItemsSection
					key={"section-" + idx}
					dividerBefore
					sectionTitle={formSections[section].sectionTitle}
					style={{ display: "flex", flexDirection: "column" }}
					defaultCollapsed={formSections[section].defaultCollapsed}
				>
					{renderAttachmentSection()}
				</FormItemsSection>
			);
		}
		const isGeneralInfoWithBoxSelect =
			section === "GENERAL" && formSections[section].includeBoxSelect;

		if (
			formSections[section].sectionTitle == "Advanced info" &&
			isExpermentItemCreate
		) {
			return (
				<>
					<InfoSectionDivider className={styles.divider} />
					<div
						className={styles.advancedInfo}
						key={formSections[section].sectionTitle}
					>
						<CollapsibleContainer
							hideEditBtn
							key={formSections[section].sectionTitle}
							title={formSections[section].sectionTitle}
						>
							<FormItemsSection
								key={"section-" + idx}
								dividerBefore={section !== "GENERAL"}
								dividerAfter={section === "GENERAL"}
								defaultCollapsed={
									formSections[section].defaultCollapsed
								}
							>
								<>
									{section === "ADVANCED_INFO" &&
										custonFormItemFields}
									{formSections[section].fields &&
										renderSectionItems(
											formSections[section].fields
										)}
									{isGeneralInfoWithBoxSelect && (
										<BoxSelect
											box={box}
											setBox={setBox}
											isExpermentItemCreate={
												isExpermentItemCreate
											}
											amountOfCopies={amountOfCopies}
											setAmountOfCopies={
												setAmountOfCopies
											}
										/>
									)}
								</>
							</FormItemsSection>
						</CollapsibleContainer>
					</div>
				</>
			);
		}

		return (
			<FormItemsSection
				key={"section-" + idx}
				dividerBefore={section !== "GENERAL"}
				sectionTitle={formSections[section].sectionTitle}
				defaultCollapsed={formSections[section].defaultCollapsed}
			>
				<>
					{section === "ADVANCED_INFO" && custonFormItemFields}
					{formSections[section].fields &&
						renderSectionItems(formSections[section].fields)}
					{isGeneralInfoWithBoxSelect && (
						<BoxSelect
							box={box}
							setBox={setBox}
							isExpermentItemCreate={isExpermentItemCreate}
							amountOfCopies={amountOfCopies}
							setAmountOfCopies={setAmountOfCopies}
						/>
					)}
				</>
			</FormItemsSection>
		);
	});

	type ChildProps = {
		onClick?: () => void;
		disabled?: boolean;
	};
	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement<ChildProps>(child)) {
			return React.cloneElement(child, {
				onClick: handleSubmitWrap,
				disabled: isSubmitBtnDisabled,
			});
		}
		return child;
	});

	return (
		<LayerSystemContainer
			className={cn(styles.createNewItem, "create-new-item-container", {
				[styles.isRepositoryPage]: isRepositoryPage,
			})}
			overrideLayer={layerColor}
		>
			<form className="information-form" autoComplete="off">
				{!withoutHeader && renderHeader}
				<div className="form-body">{renderSections}</div>
				{childrenWithProps}
				<DeleteFileModal
					visible={!!attachmentToDelete}
					onCancel={() => setAttachmentToDelete(null)}
					onDelete={() =>
						handleDeleteTempItemAttachment(attachmentToDelete)
					}
				/>
			</form>
		</LayerSystemContainer>
	);
}

function BoxSelect({
	box,
	setBox,
	amountOfCopies,
	setAmountOfCopies,
	isExpermentItemCreate,
}: {
	box: Box | null;
	setBox: (value: Box | null) => void;
	amountOfCopies: number | undefined;
	setAmountOfCopies: (value: number | undefined) => void;
	isExpermentItemCreate?: boolean;
}) {
	const [boxSearch, setBoxSearch] = useState<string>("");
	const { setHasErrors } = useContext(ErrorsContext);
	const onSetBoxSearch = useDebounceEventHandler((value: string) => {
		setBoxSearch(value);
	}, 350);
	const { data: boxSearchData } = useGetRepositoryBoxesQuery({
		page: 1,
		page_size: 10,
		search: boxSearch?.startsWith("#") ? undefined : boxSearch,
		ordering: "-updated_at",
		status__in: [ITEM_STATUS.ACTIVE],
	});

	const maxSpaces = box ? box.rows * box.columns : 1;
	const avaibableSpaces = maxSpaces - (box ? box.item_count : 0);

	function truncateLayerName(layerName: string) {
		if (layerName.length > MAX_LAYER_NAME) {
			return layerName.slice(0, MAX_LAYER_NAME) + "...";
		}
		return layerName;
	}

	useEffect(() => {
		if (box && amountOfCopies && amountOfCopies > avaibableSpaces) {
			setHasErrors(true);
		} else {
			setHasErrors(false);
		}
	}, [amountOfCopies, avaibableSpaces]);

	const renderBoxLocation = (location_data: BoxLocationData) => {
		const { freezer_name, rack_name, shelf_name, category_name } =
			location_data;
		let location = "";
		if (freezer_name) {
			location = truncateLayerName(freezer_name);
		}
		if (shelf_name) {
			const truncatedShelfName = truncateLayerName(shelf_name);
			location +=
				location !== ""
					? ` > ${truncatedShelfName}`
					: truncatedShelfName;
		}
		if (rack_name || category_name) {
			const rack_or_category =
				truncateLayerName(rack_name || "") ||
				truncateLayerName(category_name || "");
			location +=
				location !== "" ? ` > ${rack_or_category}` : rack_or_category;
		}
		return location;
	};

	return (
		<>
			<div
				style={{
					gridColumn: "first-half-start / second-half-end",
				}}
			>
				<Typography
					variant="label"
					bold
					style={{
						marginBottom: "8px",
						fontFamily: "var(--text-primary)",
					}}
				>
					Location
				</Typography>
				<SelectV2
					wrapperClassname={styles.locationSelect}
					allowClear
					showSearch
					value={box?.name || boxSearch || undefined}
					onSelect={(n) => {
						const [idStr] = (n as string).split("-");
						const selectedId = parseInt(idStr, 10);
						const boxFinded = boxSearchData?.results.find(
							(box) => box.id === selectedId
						);

						if (boxFinded) {
							setBox(boxFinded);
						}
					}}
					notFoundContent={
						<div>
							<span>Box not found</span>
						</div>
					}
					onSearch={(value) => onSetBoxSearch(value)}
					placeholder="Search by box name or id"
					dataCy="create-item-box-select"
				>
					{boxSearchData?.results.map((option: Box) => {
						const boxMaxSpaces = option.rows * option.columns;
						const boxAvaibableSpaces =
							boxMaxSpaces - option.item_count;
						const availability =
							boxAvaibableSpaces > 0 ? boxAvaibableSpaces : "No";
						return (
							// Value property on Select is a string because its: box?.name || boxSearch
							// Select.Option value should include the Select value
							// and a unique identifier to avoid weird UI issues
							<SelectV2.Option
								key={option.id}
								value={`${option.id}-${option.name}`}
								disabled={boxMaxSpaces === option.item_count}
								className={styles.boxSelectItem}
							>
								<div>
									<div className={styles.boxNameContainer}>
										<span
											style={{
												color: "var(--text-secondary)",
											}}
										>
											{option.name}
										</span>
										<span
											style={{
												color: "var(--text-tertiary)",
											}}
										>
											{availability}
											{" slots available"}
										</span>
									</div>
									<Typography
										color="text-tertiary"
										variant="caption"
									>
										{renderBoxLocation(
											option.location_data
										)}
									</Typography>
								</div>
							</SelectV2.Option>
						);
					})}
				</SelectV2>
			</div>
			{!isExpermentItemCreate && (
				<>
					<div
						style={{
							gridColumn: "first-half-start / second-half-end",
						}}
					>
						<Typography
							variant="label"
							bold
							style={{
								fontFamily: "var(--text-primary)",
							}}
						>
							How many copies add to the same location?
						</Typography>
					</div>
					<div
						style={{
							gridColumn: "first-half-start / first-half-end",
						}}
					>
						<InputNumberV2
							wrapperProps={{
								className: "amoutOfCopiesInput",
								style: {
									width: "100%",
								},
							}}
							error={
								box &&
								amountOfCopies &&
								amountOfCopies > avaibableSpaces
									? `Value must be ${avaibableSpaces} or less`
									: undefined
							}
							type="number"
							max={box ? avaibableSpaces : 20}
							min={amountOfCopies === undefined ? undefined : 1}
							value={
								amountOfCopies === undefined
									? ""
									: amountOfCopies
							}
							onChange={(e) => {
								const v = parseInt(e.toString(), 10);
								if (isNaN(v) || v < 0) {
									setAmountOfCopies(undefined);
									return;
								}
								setAmountOfCopies(Math.floor(v));
							}}
							inputProps={{
								className: styles.inputWidth,
							}}
							useNumberArrows
							dataCy="amountOfCopies"
							validators={[
								{
									validator: (text) =>
										0 < parseInt(text, 10) &&
										parseInt(text, 10) <= 20,
									error: "You must enter a number between 1 and 20",
								},
							]}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default CreateNewItem;
