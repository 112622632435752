import React, { useState } from "react";
import { Icon, Menu, message } from "antd";
import { Dropdown } from "antdv5";
import {
	GenemodIcon,
	Demo,
	DemoSection,
	DemoWrapper,
	Typography,
} from "@components";
import classNames from "classnames";
import { DropDownProps } from "antdv4/lib/dropdown";
import styles from "./dropdownV2.module.scss";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import { Link, LinkProps } from "@common/helpers/Hooks/UseRouterDom";
import SubMenu, { SubMenuProps } from "antd/lib/menu/SubMenu";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { Color } from "@common/styles/Colors";

// TODO: We're currently mixing up the types from antd and antdv4, so we need to fix this by either using antdv4 fully, or upgrading to antdv5 fully for this component

type GenemodDropDownProps = {
	/** the trigger to show dropdown menu (default = ["click"]) */
	trigger?: ("click" | "hover" | "contextMenu")[];
	/** the style of overlay  */
	overlayClassName?: string;
	/** Choose between "meatballs" and" if type is undefined, use props.children */
	type?: "meatballs" | "kabob";
	/**
	 * Color of the icon. Defaults to "text-secondary".
	 */
	iconColor?: Color;
	/** Choose between "normal" and "compact". If not given, */
	size?: "normal" | "compact";
	/** Called when the visible state is changed */
	onVisibleChange?: (visible: boolean) => void;
	/** children of DropDown component */
	children?: React.ReactNode;
	hoverEffect?: boolean;
	shadowHover?: boolean;
	dataCy?: string;
} & DropDownProps;

const OPTION_HEIGHT = 48;

/**
 * Use same way as antd Dropdown but comes with meatball and kabob options
 * Replaces all children with an icon if type is used.
 * Use "Normal“ size with icons with DEFAULT size and use ”Compact“ size with icons with SMALL size
 */
export default function DropdownV2(props: GenemodDropDownProps): JSX.Element {
	const {
		type,
		size = "normal",
		children,
		overlayClassName,
		iconColor = "text-secondary",
		dataCy,
		...newProps
	} = props;
	const getDropdownChildren = () => {
		switch (type) {
			case "meatballs":
				return (
					<div
						className={classNames(
							"meatballsContainer",
							styles.genemodDropdownIconBg
						)}
						data-cy={`${dataCy}-btn`}
					>
						<GenemodIcon
							name="meatballs"
							fill={iconColor}
							size={
								size && size === "compact" ? "default" : "large"
							}
							hoverEffect={props.hoverEffect}
							shadowHover={props.shadowHover}
							dataCy={props.dataCy}
						/>
					</div>
				);
			case "kabob":
				return (
					<div
						className={styles.genemodDropdownIconBg}
						data-cy={`${dataCy}-btn`}
					>
						<GenemodIcon
							name="kabob"
							fill={iconColor}
							size={
								size && size === "compact" ? "default" : "large"
							}
							hoverEffect={props.hoverEffect}
							shadowHover={props.shadowHover}
						/>
					</div>
				);
		}
		if (!children) {
			return <>No children defined</>;
		}
		return children;
	};
	let _overlayClasses = classNames(styles.genemodDropdown, overlayClassName);
	if (size === "compact") {
		_overlayClasses = classNames(
			_overlayClasses,
			styles.genemodDropdown__isCompact
		);
	}

	/**
	 * to get Container which dropdown would be sticky on
	 * NOTE:: If the only container that fulfills the condition is a scrollable container,
	 * this won't work. In this case, we need to set getPopupContainer separately.
	 */
	const getPopupContainer = (
		node: (Node & ParentNode) | null,
		menuHeight: number
	): HTMLElement | null => {
		if (!node || !(node instanceof HTMLElement)) {
			return null;
		}
		if (
			node.scrollHeight - node.clientHeight > OPTION_HEIGHT ||
			node.scrollHeight > menuHeight
		) {
			return node;
		}
		return getPopupContainer(node.parentNode, menuHeight);
	};

	return (
		<Dropdown
			overlayClassName={_overlayClasses}
			{...newProps}
			rootClassName={styles.dropdown}
			trigger={props.trigger || ["click"]}
			onVisibleChange={props.onVisibleChange}
		>
			{getDropdownChildren()}
		</Dropdown>
	);
}

export function DROPDOWNV2_DEMO(): JSX.Element {
	function handleMenuClick(e: any) {
		message.info("Click on menu item.");
		console.log("click", e);
	}

	const menu1 = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="1">
				<Icon type="user" /> 1st menu item long
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="2">
				<Icon type="user" /> 2nd menu item
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3">
				<Icon type="user" /> 3rd menu item
			</Menu.Item>
		</Menu>
	);

	const menu2 = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="1">1st menu item long</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="2">2nd menu item</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3">3rd menu item</Menu.Item>
		</Menu>
	);

	return (
		<div className={styles.genemodDropdownDemo}>
			<DemoWrapper>
				<DemoSection>
					<Demo
						title="Normal dropdown menus"
						description="Dropdown is a wrapper class that is used to display a stylized menu after the user interacts with an element. 
						Normal dropdown menues are used with DEFAULT icons."
					>
						<DropdownV2 overlay={menu1} type="meatballs" />
						<br />
						<DropdownV2 overlay={menu1} type="kabob" />
					</Demo>
					<Demo
						title="Compact dropdown menus"
						description="Dropdown is a wrapper class that is used to display a stylized menu after the user interacts with an element. 
						Compact dropdown menues are used with SMALL icons."
					>
						<DropdownV2
							overlay={menu2}
							type="meatballs"
							size="compact"
						/>
						<br />
						<DropdownV2
							overlay={menu2}
							type={"kabob"}
							size="compact"
						/>
					</Demo>
					<Demo
						title="Context menus"
						description="Can be used to display a context menu as well. It will also follow the compact style."
					>
						<DropdownV2
							overlay={menu2}
							trigger={["contextMenu"]}
							size="compact"
						>
							<div
								style={{
									height: "300px",
									width: "300px",
									background: "white",
								}}
							>
								Right click somewhere
							</div>
						</DropdownV2>
					</Demo>
				</DemoSection>
			</DemoWrapper>
		</div>
	);
}

export type MenuOptions = Readonly<
	{
		label: string;
		icon?: IconName | React.ReactNode;
		action?: () => void;
		color?: Color;
		isBorder?: boolean;
		dataCy?: string;
	}[]
>;

type DropDownWithItemsProps<M extends MenuOptions> = Omit<
	GenemodDropDownProps,
	"overlay"
> & {
	menu: M;
	/**
	 * Extra function to be called after click handler completes. Only added to get close functionality on DropDownControlledWithItems.
	 */
	_afterClickHandler?: () => void;
};

/**
 * Creates a dropdown component but configures the overlay menu using a "MenuConfig" prop.
 */
export const DropDownWithItemsV2 = <M extends MenuOptions>({
	menu,
	_afterClickHandler,
	...props
}: DropDownWithItemsProps<M>): JSX.Element => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			data-cy={props.dataCy}
			className="dropdown-wrapper"
		>
			<DropdownV2
				{...props}
				dataCy={props.dataCy}
				overlay={
					<Menu
						onClick={(e) => {
							e.domEvent.stopPropagation();
							e.domEvent.preventDefault();
							menu.find((opt) => opt.label === e.key)?.action?.();
							_afterClickHandler?.();
						}}
					>
						{menu.map(({ label, icon, color, isBorder }) => {
							if (isBorder) {
								return <DropdownMenuDivider key={label} />;
							}
							return (
								<DropdownMenuItem
									label={label}
									key={label}
									icon={icon}
									color={color}
									dataCy={`menu-${label
										.toLowerCase()
										.replace(" ", "-")}`}
								/>
							);
						})}
					</Menu>
				}
			>
				{props.children}
			</DropdownV2>
		</div>
	);
};

/**
 * Same as DropDownWithItems but controls visiblity internally.
 */
export function DropDownUncontrolledWithItemsV2<M extends MenuOptions>(
	props: Omit<DropDownWithItemsProps<M>, "visible" | "onVisibleChange">
) {
	const [visible, setVisible] = useState(false);
	return (
		<DropDownWithItemsV2
			{...props}
			visible={visible}
			onVisibleChange={setVisible}
			_afterClickHandler={() => setVisible(false)}
		/>
	);
}

type DropdownMenuItemProps = MenuItemProps & {
	label: string;
	icon?: IconName | React.ReactNode;
	linkTo?: LinkProps["to"];
	suffix?: React.ReactNode;
	color?: Color | "none";
	dataCy?: string;
};

/**
 * Makes an antd menu.item component with some extra options like icons, links, suffixes
 */
export function DropdownMenuItem({
	label,
	icon,
	linkTo,
	suffix,
	color = "text-secondary",
	dataCy,
	// Note: antd passes props behind the scenes, these need to be passed through. Beware of using
	// props from antdInternalProps inside this component directly since they seem to mostly be
	// injected later on.
	...antdInternalProps
}: DropdownMenuItemProps) {
	return (
		<Menu.Item data-cy={dataCy} {...antdInternalProps}>
			<DropdownMenuOrSubmenuInner
				label={label}
				icon={icon}
				linkTo={linkTo}
				suffix={suffix}
				color={color}
			/>
		</Menu.Item>
	);
}

type DropdownSubMenuItemProps = SubMenuProps & {
	label: string;
	icon?: IconName | React.ReactNode;
	children: React.ReactNode;
	color?: Color | "none";
};
/**
 * Makes an antd submenu component with some extra options like icons
 */
export function DropdownSubMenuItem({
	label,
	icon,
	children,
	color = "text-secondary",
	// Note: antd passes props behind the scenes, these need to be passed through. Beware of using
	// props from antdInternalProps inside this component directly since they seem to mostly be
	// injected later on.
	...antdInternalProps
}: DropdownSubMenuItemProps) {
	return (
		<SubMenu
			{...antdInternalProps}
			popupClassName={styles.genemodDropdown}
			title={
				<DropdownMenuOrSubmenuInner
					label={label}
					icon={icon}
					suffix={<GenemodIcon name="caret-right" />}
					color={color}
				/>
			}
		>
			{children}
		</SubMenu>
	);
}

type DropdownMenuOrSubmenuInnerProps = {
	label: string;
	icon?: IconName | React.ReactNode;
	linkTo?: LinkProps["to"];
	suffix?: React.ReactNode;
	color?: Color | "none";
};
function DropdownMenuOrSubmenuInner({
	label,
	icon,
	linkTo,
	suffix,
	color = "text-secondary",
}: DropdownMenuOrSubmenuInnerProps) {
	const renderIcon = () => {
		if (!icon) return null;
		if (typeof icon === "string") {
			return (
				<GenemodIcon
					name={icon as IconName}
					color={color !== "none" ? color : "text-secondary"}
					fill={color !== "none" ? color : "text-secondary"}
				/>
			);
		}
		return <GenemodIcon>{icon as any}</GenemodIcon>;
	};
	const inner = (
		<>
			{renderIcon()}
			<Typography variant="label" color={color}>
				{label}
			</Typography>
			{suffix}
		</>
	);

	if (linkTo) {
		return (
			<Link className={styles.menuOption} to={linkTo}>
				{inner}
			</Link>
		);
	}
	return <div className={styles.menuOption}>{inner}</div>;
}

// A component for menu divider
export const DropdownMenuDivider = () => (
	<Menu.Divider className={styles.menuDivider} />
);
